import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducer';
import {interceptor} from "../utils/Interceptor";
import {persistStore} from "redux-persist";

const configureStore = (preloadedState) => {
    const middlewares = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    return createStore(rootReducer(), preloadedState, composedEnhancers);
}
let store = configureStore();
interceptor(store);
export const persistor = persistStore(store);
export default store;