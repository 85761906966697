import {dispatchAction, getError} from "../../utils/common";
import APIConstant from '../../utils/constants';
import {toast} from 'react-toastify';
import http from '../../utils/Interceptor';

export const SET_DATA = '[Claims] Set Redux Data';

export const updateSingleFieldForClaim = (fieldKey, value, claimId) => async (dispatch) => {
    try {
        const data = {
            "fieldData": {[fieldKey]: value},
            "CurrentColumn": fieldKey,
            "CurrentTable": "Claim",
            "CurrentFiled": "ClaimId",
            "CurrentValue": claimId
        }
        const res = await http.post(APIConstant.path.UpdateSingleFieldForClaim, data);
        if (res?.success) {
            toast.success('Claim ' + fieldKey + ' Updated SuccessFully');
            dispatch(getClaimDetail({"ClaimId": claimId}));
        }
    } catch (e) {
        getError(e);
    }
}
export const getClaimDetail = (data) => async (dispatch) => {
    try {
        dispatchAction(dispatch, SET_DATA, {prop: 'claimDetail', data: {}});
        const res = await http.post('getClaimDetail', data);
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'claimDetail', data: res.data});
            return res.data;
        }
    } catch (e) {
        getError(e);
    }
}
export const getPolicyDataForClaim = (data) => async (dispatch) => {
    try {
        dispatchAction(dispatch, SET_DATA, {prop: 'policyDetail', data: {}});
        const res = await http.post(APIConstant.path.getPolicyDetailForClaim, data);
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'policyDetail', data: res.data});
        }
        return res;
    } catch (e) {
        getError(e);
    }
}
export const getSurveyorList = () => async (dispatch) => {
    try {
        const res = await http.post('getSurveyorList', {ContactType: 'Surveyor'});
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'surveyerNameArr', data: res.data[0]});
        }
        return res;
    } catch (e) {
        getError(e);
    }
}
export const getPolicyHolder = (data) => async (dispatch) => {
    try {
        const res = await http.post('getPolicyHolderDetails', data);
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'policyHolder', data: res.data[0][0]});
        }
    } catch (e) {
        getError(e);
    }
}
export const getSpGetUsersByTeam = (data) => async (dispatch) => {
    try {
        const res = await http.post('getSpGetUsersByTeam', data);
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'referrList', data: res.data});
        }
    } catch (e) {
        getError(e);
    }
}

export const getMooringData = (data) => async () => {
    try {
        return await http.post('getMooringList', data);
    } catch (e) {
        getError(e);
    }
}
export const getClaimStatus = (ClaimId) => async () => {
    try {
        const res = await http.post('getClaimStatus', {ClaimId});
        if (res?.success) {
            return res.data[0]?.Status
        }
    } catch (e) {
        getError(e);
    }
}
export const getClaimReceiveDate = (ClaimId) => async () => {
    try {
        const res = await http.post('getClaimReceiveDate', {ClaimId});
        if (res?.success) {
            return res.data[0]?.DateClaimFormRec
        }
    } catch (e) {
        getError(e);
    }
}
export const getClaimPaymentInfoByClaimId = (ClaimId) => async () => {
    try {
        const res = await http.post('getClaimPaymentInfoByClaimId', {ClaimId});
        if (res?.success) {
            return res.data;
        }
    } catch (e) {
        getError(e);
    }
}

export const getOpenClaimCount = (StaffId) => async () => {
    try {
        const res = await http.post('getOpenClaimCount', {StaffId});
        if (res?.success) {
            return res.data;
        }
    } catch (e) {
        getError(e);
    }
}
export const addOrUpdateClaimThirdParty = (data) => async () => {
    try {
        return await http.post('addOrUpdateClaimThirdParty', data);
    } catch (e) {
        getError(e);
    }
}
export const deleteEstimateDoc = (id) => async () => {
    try {
        return await http.post('deleteEstimateDoc', {id});
    } catch (e) {
        getError(e);
    }
}
export const getNewArkSurveyorDetails = (data) => async () => {
    try {
        return await http.post('getNewArkSurveyorDetails', data);
    } catch (e) {
        getError(e);
    }
}
export const uploadDocument = (data) => async () => {
    try {
        return await http.post('uploadDocument', data);
    } catch (e) {
        getError(e);
    }
}
export const getClaimSurveyorReport = (id) => async () => {
    try {
        return await http.post('getClaimSurveyorReport', {id});
    } catch (e) {
        getError(e);
    }
}
export const getSurveyorContcatDetails = (SurveyorId) => async () => {
    try {
        return await http.post('getSurveyorContcatDetails', {SurveyorId});
    } catch (e) {
        getError(e);
    }
}
export const getClaimManagerDetails = (ClaimId) => async () => {
    try {
        return await http.post('getClaimManagerDetails', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const getPassengerDetails = (ClaimId) => async () => {
    try {
        return await http.post('getPassengerDetails', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const getLostPhotos = (id) => async () => {
    try {
        return await http.post('getLostPhotos', {id});
    } catch (e) {
        getError(e);
    }
}
export const addEditClaimReportParty = (data) => async () => {
    try {
        return await http.post('addEditClaimReportParty', data);
    } catch (e) {
        getError(e);
    }
}
export const addEditClaimTheftReport = (data) => async () => {
    try {
        return await http.post('addEditClaimTheftReport', data);
    } catch (e) {
        getError(e);
    }
}
export const addUpdateClaim = (data) => async () => {
    try {
        return await http.post(APIConstant.path.addUpdateClaim, data);
    } catch (e) {
        getError(e);
    }
}
export const sendEmailOnNewClaim = (data) => async () => {
    try {
        return await http.post('sendEmailOnNewClaim', data);
    } catch (e) {
        getError(e);
    }
}
export const getTheftDetails = (ClaimId) => async () => {
    try {
        return await http.post('getTheftDetails', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const getWitnessesDetails = (ClaimId) => async () => {
    try {
        return await http.post('getWitnessesDetails', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const getAwaitingStage = (Stage) => async () => {
    try {
        return await http.post('getAwaitingStage', {Stage});
    } catch (e) {
        getError(e);
    }
}
export const getMooringList = (PolicyId) => async () => {
    try {
        return await http.post('getMooringList', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const addUpdateClaimSurveyer = (data) => async () => {
    try {
        return await http.post(APIConstant.path.addUpdateClaimSurveyer, data);
    } catch (e) {
        getError(e);
    }
}
export const updateClaimData = (data) => async () => {
    try {
        return await http.post('updateClaimData', data);
    } catch (e) {
        getError(e);
    }
}
export const saveClaimReferr = (data) => async () => {
    try {
        return await http.post('saveClaimReferr', data);
    } catch (e) {
        getError(e);
    }
}
export const getMooringGuideDetails = (data) => async () => {
    try {
        return await http.post('getMooringGuideDetails', data);
    } catch (e) {
        getError(e);
    }
}
export const getClaimReferrData = (ClaimId) => async () => {
    try {
        return await http.post('getClaimReferrData', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const validateClaimNumber = (data) => async () => {
    try {
        return await http.post('validateClaimNumber', data);
    } catch (e) {
        getError(e);
    }
}
export const getBinderByPolicyId = (PolicyId) => async () => {
    try {
        return await http.post('getBinderByPolicyId', {PolicyId});
    } catch (e) {
        return getError(e);
    }
}
export const editClaimStage = (data) => async () => {
    try {
        return await http.post('editClaimStage', data);
    } catch (e) {
        getError(e);
    }
}
export const getClaimSingleNote = (data) => async () => {
    try {
        return await http.post('getClaimSingleNote', data);
    } catch (e) {
        getError(e);
    }
}
export const getClaimStage = (ClaimId) => async () => {
    try {
        return await http.post('getClaimStage', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const addEditClaimStage = (data) => async () => {
    try {
        return await http.post('addEditClaimStage', data);
    } catch (e) {
        return getError(e);
    }
}
export const sendClaimEmailAfterTheParticularEvent = (data) => async () => {
    try {
        return await http.post('sendClaimEmailAfterTheParticularEvent', data);
    } catch (e) {
        return getError(e);
    }
}
export const getClaimTargetStageList = (data) => async () => {
    try {
        return await http.post('getClaimTargetStageList', data);
    } catch (e) {
        getError(e);
    }
}
export const addNotes = (data) => async () => {
    try {
        return await http.post('addNotes', data);
    } catch (e) {
        getError(e);
    }
}
export const getClaimReview = (ClaimId) => async () => {
    try {
        return await http.post('getClaimReview', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const addEditClaimReview = (data) => async () => {
    try {
        return await http.post('addEditClaimReview', data);
    } catch (e) {
        return getError(e);
    }
}
export const addEditClaimReserve = (data) => async () => {
    try {
        return await http.post('addEditClaimReserve', data);
    } catch (e) {
        return getError(e);
    }
}
export const UpdateClaimDetailReserve = (data) => async () => {
    try {
        return await http.post('UpdateClaimDetailReserve', data);
    } catch (e) {
        return getError(e);
    }
}
export const getClaimReserve = (ClaimId) => async () => {
    try {
        return await http.post('getClaimReserve', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const getUserBankAccountDetails = (data) => async () => {
    try {
        return await http.post(APIConstant.path.getUserBankAccountDetails, data);
    } catch (e) {
        getError(e);
    }
}
export const updateClaimFields = (data) => async () => {
    try {
        return await http.post('UpdateSingleFieldForClaim', data);
    } catch (e) {
        getError(e);
    }
}
export const updateClaimPaymentTransaction = (data) => async () => {
    try {
        return await http.post(APIConstant.path.updateClaimPaymentTransaction, data);
    } catch (e) {
        return getError(e);
    }
}
export const GetChildClaimAccountRecords = (data) => async () => {
    try {
        return await http.post(APIConstant.path.GetChildClaimAccountRecords, data);
    } catch (e) {
        return getError(e);
    }
}
export const claimProcessAccountEntry = (data) => async () => {
    try {
        return await http.post(APIConstant.path.claimProcessAccountEntry, data);
    } catch (e) {
        return getError(e);
    }
}
export const addClaimNotes = (data) => async () => {
    try {
        return await http.post('addClaimNotes', data);
    } catch (e) {
        getError(e);
    }
}

export const autoGenerateClaimInvoiceDocument = (data) => async () => {
    try {
        return await http.post('autoGenerateClaimInvoiceDocument', data);
    } catch (e) {
        getError(e);
    }
}
export const getEstimationDocs = (id) => async () => {
    try {
        return await http.post('getEstimationDocs', {id});
    } catch (e) {
        getError(e);
    }
}
export const addClaimInvoice = (data) => async () => {
    try {
        return await http.post('addClaimInvoice', data);
    } catch (e) {
        return getError(e);
    }
}
export const getClaimAccountList = (ClaimId) => async () => {
    try {
        return await http.post('getClaimAccountList', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const getContactListFromContactType = (ContactType) => async () => {
    try {
        return await http.post('getContactListFromContactType', {ContactType});
    } catch (e) {
        getError(e);
    }
}
export const getUserDataForSanctionsSearch = (PolicyId) => async () => {
    try {
        return await http.post('getUserDataForSanctionsSearch', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const saveSanctionsSearchResult = (data) => async () => {
    try {
        return await http.post('saveSanctionsSearchResult', data);
    } catch (e) {
        return getError(e);
    }
}
export const startSanctionsSearch = (data) => async () => {
    try {
        return await http.post('startSanctionsSearch', data);
    } catch (e) {
        return getError(e);
    }
}
export const getSanctionSearchResultDetails = (data) => async () => {
    try {
        return await http.post('getSanctionSearchResultDetails', data);
    } catch (e) {
        getError(e);
    }
}

export const getManualSanctionsSearchDetails = (SantionsSearchId,EntityDetails) => async () => {
    try {
        return await http.post('getManualSanctionsSearchDetails',{SantionsSearchId,EntityDetails});
    } catch (e) {
        getError(e);
    }
}
export const updateSanctionsSearchResult = (data) => async () => {
    try {
        return await http.post('updateSanctionsSearchResult', data);
    } catch (e) {
        return getError(e);
    }
}
export const getClaimEstimation = (ClaimId) => async () => {
    try {
        return await http.post('getClaimEstimation', {ClaimId});
    } catch (e) {
        getError(e);
    }
}
export const addEditClaimEstimation = (data) => async () => {
    try {
        return await http.post('addEditClaimEstimation', data);
    } catch (e) {
        return getError(e);
    }
}
export const assignClaimHandler = (data) => async () => {
    try {
        return await http.post('assignClaimHandler', data);
    } catch (e) {
        return getError(e);
    }
}
export const getClaimList = (pSearchType,data) => async () => {
    try {
        let apiURL = APIConstant.path.getClaimListWherePaymentDue;
        if (pSearchType === 'ALLCLAIMS') {
            apiURL = APIConstant.path.getClaimList;
        }
        return await http.post(apiURL, data);
    } catch (e) {
        return getError(e);
    }
}

export const getClaimPaymentReportByUser = (DraftStatus) => async () => {
    try {
        return await http.post(APIConstant.path.getClaimPaymentReportByUser, {DraftStatus});
    } catch (e) {
        getError(e);
    }
}