import {getError} from "../../../utils/common";
import http from '../../../utils/Interceptor';
import APIConstant from "../../../utils/constants";

export const SET_DATA = '[Policy Account] Set Redux Data';

export const getPolicyDepartment = (PolicyId) => async () => {
    try {
        const res = await http.post('getPolicyDepartment', {PolicyId});
        return (res?.success && res.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
        return "";
    }
}
export const saveTransferFund = (data) => async () => {
    try {
        return await http.post('saveTransferFund', data);
    } catch (e) {
        return getError(e);
    }
}
export const getPaymentListForRefundProcess = (PolicyNumber) => async () => {
    try {
        return await http.post('getPaymentListForRefundProcess', {PolicyNumber});
    } catch (e) {
        return getError(e);
    }
}
export const startStripeRefundProcess = (data) => async () => {
    try {
        return await http.post('startStripeRefundProcess', data);
    } catch (e) {
        return getError(e);
    }
}
export const saveManualSEPAPaymentDetails = (data) => async () => {
    try {
        return await http.post('saveManualSEPAPaymentDetails', data);
    } catch (e) {
        getError(e);
    }
}
export const savepaymentdata = (data) => async () => {
    try {
        return await http.post('savepaymentdata', data);
    } catch (e) {
        return getError(e);
    }
}
export const saveMakePaymentEntry = (data) => async () => {
    try {
        return await http.post('saveMakePaymentEntry', data);
    } catch (e) {
        return getError(e);
    }
}
export const getpaymentholderdetails = (policyId) => async () => {
    try {
        return await http.post('getpaymentholderdetails', {policyId});
    } catch (e) {
        getError(e);
    }
}
export const getPolicyComputation = (policyId) => async () => {
    try {
        return await http.post('getPolicyComputation', {policyId});
    } catch (e) {
        getError(e);
    }
}

export const generateReciept = (PolicyAccountId) => async () => {
    try {
        return await http.post('generateReciept', {PolicyAccountId});
    } catch (e) {
        getError(e);
    }
}
export const getUserBankAccountDetails = (EntityId,EntityType) => async () => {
    try {
        return await http.post(APIConstant.path.getUserBankAccountDetails, {EntityId,EntityType});
    } catch (e) {
        getError(e);
    }
}
export const getLapsePolicyDetails = (PolicyId,LoggedInUserId) => async () => {
    try {
        return await http.post('getLapsePolicyDetails', {PolicyId,LoggedInUserId});
    } catch (e) {
        getError(e);
    }
}
export const getAccountTransferFund = (PolicyId) => async () => {
    try {
        return await http.post('getAccountTransferFund', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const getChildAccountRecords = (UniqueReferenceId) => async () => {
    try {
        return await http.post('getChildAccountRecords', {UniqueReferenceId});
    } catch (e) {
        getError(e);
    }
}
export const addUpdateBankDetails = (data) => async () => {
    try {
        return await http.post('addUpdateBankDetails', data);
    } catch (e) {
        getError(e);
    }
}
export const processAccountEntry = (data) => async () => {
    try {
        return await http.post('processAccountEntry', data);
    } catch (e) {
        getError(e);
    }
}
export const lapsePolicy = (data) => async () => {
    try {
        return await http.post('lapsePolicy', data);
    } catch (e) {
        getError(e);
    }
}
export const assignSpanishDebitFlag = (PolicyId) => async () => {
    try {
        return await http.post(APIConstant.path.assignSpanishDebitFlag, {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const removeSpanishDebitFlag = (PolicyId) => async () => {
    try {
        return await http.post(APIConstant.path.removeSpanishDebitFlag, {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const getPolicyOutstandingTransaction = (data) => async () => {
    try {
        return await http.post('getPolicyOutstandingTransaction', data);
    } catch (e) {
        getError(e);
    }
}
export const getBatchPaymentDetails = (PolicyAccountId) => async () => {
    try {
        return await http.post('getBatchPaymentDetails', {PolicyAccountId});
    } catch (e) {
        getError(e);
    }
}
export const shortWriteoff = (data) => async () => {
    try {
        return await http.post('shortWriteoff', data);
    } catch (e) {
        getError(e);
    }
}
export const clearBankDetails = (data) => async () => {
    try {
        return await http.post('clearBankDetails', data);
    } catch (e) {
        getError(e);
    }
}
export const addUpdateAccountEntry = (api,data) => async () => {
    try {
        const res = await http.post(api, data);
        return res?.success ? res.data : "";
    } catch (e) {
        return getError(e);
    }
}
export const updateTransactionOutstanding = (PolicyAccountId,loggedInUserId) => async () => {
    try {
        return await http.post('updateTransactionOutstanding', {PolicyAccountId,loggedInUserId});
    } catch (e) {
        getError(e);
    }
}
export const deleteAccountById = (data) => async () => {
    try {
        return await http.post('deleteAccountById', data);
    } catch (e) {
        getError(e);
    }
}

export const getPolicyDetailsForAccount = (PolicyId,UniqueReferenceId) => async () => {
    try {
        return await http.post('getPolicyDetailsForAccount', {PolicyId,UniqueReferenceId});
    } catch (e) {
        getError(e);
    }
}