import {dispatchAction, getError} from "../../../utils/common";
import http from '../../../utils/Interceptor';
import APIConstant from '../../../utils/constants';

export const SET_DATA = '[PolicyDetailsMerged] Set Redux Data';

export const getPolicyStage = (data) => async (dispatch) => {
    try {
        const res = await http.post('getPolicyStage', data);
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'stageList', data: res.data});
        }
    } catch (e) {
        getError(e);
    }
}

export const getSpGetUsersByTeam = (data) => async (dispatch) => {
    try {
        const res = await http.post('getSpGetUsersByTeam', data);
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'referrList', data: res.data});
        }
    } catch (e) {
        getError(e);
    }
}

export const getPolicyLeadData = (PolicyId) => async (dispatch) => {
    try {
        const res = await http.post('getPolicyLeadData', {PolicyId});
        if (res?.success && res.data.length) {
            dispatchAction(dispatch, SET_DATA, {prop: 'leadData', data: res.data[0]});
        }
    } catch (e) {
        getError(e);
    }
}

export const getMooringGuideDetails = (data,isReturn=false) => async (dispatch) => {
    try {
        const res = await http.post('getMooringGuideDetails', data);
        if (res?.success && !isReturn) {
            dispatchAction(dispatch, SET_DATA, {prop: 'mooringBoatMooredarr', data: res.data});
        } else { return res; }
    } catch (e) {
        getError(e);
    }
}

export const getReferredName = (PolicyId) => async (dispatch) => {
    try {
        const res = await http.post('getReferredName', {PolicyId});
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'referredToName', data: res.data.referredTo});
        }
    } catch (e) {
        getError(e);
    }
}

export const getMooringList = (PolicyId) => async () => {
    try {
        return await http.post('getMooringList', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const getCoOwnerDetails = (PolicyId) => async () => {
    try {
        const res = await http.post('getCoOwnerDetails', {PolicyId});
        if (res?.success && res?.data.length) {
            return res.data[0];
        }
    } catch (e) {
        getError(e);
    }
}
export const getSubBinderData = (BinderId) => async () => {
    try {
        const res = await http.post('getSubBinderData', {BinderId});
        if (res?.success) {
            return res.data;
        }
    } catch (e) {
        getError(e);
    }
}

export const getSailingVesselDetails = (PolicyId) => async () => {
    try {
        const res = await http.post('getSailingVesselDetails', {PolicyId});
        if (res?.success && res?.data.length) {
            return res.data[0];
        }
    } catch (e) {
        getError(e);
    }
}

export const getSystemConfigByKey = (key) => async () => {
    try {
        const res = await http.post('getSystemConfigByKey', {key});
        return (res?.success && res.data.length) ? res.data[0].SysValue : 0;
    } catch (e) {
        getError(e);
        return 0;
    }
}

export const isChangeToMTAVisible = (PolicyId) => async () => {
    try {
        const res = await http.post('isChangeToMTAVisible', {PolicyId});
        if (res?.success) {
            return res.data;
        }
    } catch (e) {
        getError(e);
    }
}

export const updatePolicyMode = (PolicyId, Mode) => async () => {
    try {
        const res = await http.post('updatePolicyMode', {PolicyId, Mode});
        if (res?.success) {
            return true;
        }
    } catch (e) {
        getError(e);
    }
}

export const getMidTermStatus = (PolicyId) => async () => {
    try {
        const res = await http.post('getMidTermStatus', {PolicyId});
        return (res?.success && res.data && res.data.length) ? res.data : false;
    } catch (e) {
        getError(e);
    }
}

export const getMTDetails = (PolicyMidTermAdjustmentId) => async () => {
    try {
        const res = await http.post('getMTDetails', {PolicyMidTermAdjustmentId});
        return (res?.success && res.data && res.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
    }
}

export const saveRateableParam = (data) => async () => {
    try {
        const res = await http.post('saveRateableParam', data);
        return (res?.success && res.data && res.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
    }
}

export const getRateableParam = (data) => async () => {
    try {
        const res = await http.post('getRateableParam', data);
        if (res?.success) {
            return (res.data && res.data.length) ? res.data[0] : res.message;
        } else {
            return res.message;
        }
    } catch (e) {
        return getError(e);
    }
}

export const getReminderWarningMessage = (PolicyId) => async (dispatch) => {
    try {
        dispatchAction(dispatch, SET_DATA, {prop: 'policyWarningMessagesReminders', data: []});
        const res = await http.post('getReminderWarningMessage', {PolicyId});
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, {prop: 'policyWarningMessagesReminders', data: res.data});
        }
    } catch (e) {
        getError(e);
    }
}

export const createDynComputationInProgressFromActive = (data) => async () => {
    try {
        return await http.post('createDynComputationInProgressFromActive', data);
    } catch (e) {
        getError(e);
    }
}
export const dropdownbycodeforreason = (StingMapKey) => async () => {
    try {
        return await http.post('dropdownbycodeforreason', {StingMapKey});
    } catch (e) {
        getError(e);
    }
}

export const extendLock = (PolicyId) => async () => {
    try {
        return await http.post('extendLock', {PolicyId});
    } catch (e) {
        getError(e);
    }
}

export const revertPolicyDetail = (PolicyId) => async () => {
    try {
        return await http.post('revertPolicyDetail', {PolicyId});
    } catch (e) {
        getError(e);
    }
}

export const getPolicyHolderDetails = (policyId) => async () => {
    try {
        return await http.post('getPolicyHolderDetails', {policyId});
    } catch (e) {
        getError(e);
    }
}

export const getFreezingDate = (PolicyId) => async () => {
    try {
        return await http.post('getFreezingDate', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const getBinderByPolicyId = (PolicyId) => async () => {
    try {
        const res = await http.post('getBinderByPolicyId', {PolicyId});
        return (res?.success && res?.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
    }
}
export const getSurveyDataVisible = (data) => async () => {
    try {
        const res = await http.post('getSurveyDataVisible', data);
        return (res?.success && res?.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
    }
}
export const getTPOType = (PolicyId) => async () => {
    try {
        const res = await http.post(APIConstant.path.getTPOType, {PolicyId});
        return (res?.success && res?.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
    }
}
export const savePolicyStage = (data) => async () => {
    try {
        const res = await http.post('savePolicyStage', data);
        return (res?.success && res?.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
    }
}
export const getDebitAccountList = (PolicyId) => async () => {
    try {
        return await http.post('getDebitAccountList', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const IsGenerateRenewal = (policyId) => async () => {
    try {
        return await http.post(APIConstant.path.isGenerateRenewal, {policyId});
    } catch (e) {
        getError(e);
    }
}
export const showHideReverseLogicButtons = (policyId) => async () => {
    try {
        return await http.post('showHideReverseLogicButtons', {policyId});
    } catch (e) {
        getError(e);
    }
}
export const getComputationDropdown = (PolicyId) => async () => {
    try {
        return await http.post('getComputationDropdown', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const getPolicySummaryUpdated = (PolicyId, Option) => async () => {
    try {
        return await http.post('getPolicySummaryUpdated', {PolicyId, Option});
    } catch (e) {
        getError(e);
    }
}
export const getVesselDetails = (PolicyId) => async () => {
    try {
        return await http.post('getVesselDetails', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const unlapsePolicy = (PolicyId) => async () => {
    try {
        return await http.post('unlapsePolicy', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const savePolicyDetail = (data) => async () => {
    try {
        const res = await http.post('savePolicyDetail', data);
        return res?.success ? res.data : "";
    } catch (e) {
        getError(e);
    }
}
export const saveCoOwnerDetails = (data) => async () => {
    try {
        const res = await http.post('saveCoOwnerDetails', data);
        return res?.success ? res.data : "";
    } catch (e) {
        getError(e);
    }
}
export const saveSailingVesselDetails = (data) => async () => {
    try {
        const res = await http.post('saveSailingVesselDetails', data);
        return res?.success ? res.data : "";
    } catch (e) {
        getError(e);
    }
}
export const saveBoatingQualification = (data) => async () => {
    try {
        return await http.post('saveBoatingQualification', data);
    } catch (e) {
        getError(e);
    }
}
export const showDynamicComputationDetails = (PolicyId, PolicyMidTermAdjustmentId) => async () => {
    try {
        return await http.post('showDynamicComputationDetails', {PolicyId, PolicyMidTermAdjustmentId});
    } catch (e) {
        getError(e);
    }
}
export const isErrorNotes = (PolicyId) => async () => {
    try {
        return await http.post('isErrorNotes', {PolicyId});
    } catch (e) {
        getError(e);
    }
}

export const releaseLock = (PolicyId, ReleaseAll) => async () => {
    try {
        const res = await http.post('releaseLock', {PolicyId, ReleaseAll});
        if (res?.success && res.data && res.data.length) {
            return res.data[0];
        }
    } catch (e) {
        getError(e);
    }
}

export const changeStageToWON = (PolicyId) => async () => {
    try {
        const res = await http.post('changeStageToWON', {PolicyId});
        if (res?.success) {
            return true;
        }
    } catch (e) {
        getError(e);
    }
}
export const changeToRenewalConfirmed = (PolicyId) => async () => {
    try {
        const res = await http.post('changeToRenewalConfirmed', {PolicyId});
        if (res?.success) {
            return true;
        }
    } catch (e) {
        getError(e);
    }
}
export const clonePolicy = (data) => async () => {
    try {
        const res = await http.post(APIConstant.path.clonePolicy, data);
        if (res?.success) {
            return res.data[1];
        }
    } catch (e) {
        getError(e);
    }
}
export const getGeneralPolicy = (PolicyId) => async () => {
    try {
        const res = await http.post('getGeneralPolicy', {PolicyId});
        return (res?.success && res?.data.length) ? res.data[0] : "";
    } catch (e) {
        return getError(e);
    }
}

export const setDefaultVesselDetailsForMarineTradePolicies = (data) => () => {
    try {
        http.post('setDefaultVesselDetailsForMarineTradePolicies', data);
    } catch (e) {
        getError(e);
    }
}
export const getPolicyPremium = (PolicyId, LanguageId) => async () => {
    try {
        return await http.post('getPolicyPremium', {PolicyId, LanguageId});
    } catch (e) {
        getError(e);
    }
}
export const getCancelPolicyModelData = (PolicyId) => async () => {
    try {
        const res = await http.post(APIConstant.path.getCancelPolicyModelData, {PolicyId});
        return (res?.success && res?.data.length) ? res.data[0] : "";
    } catch (e) {
        getError(e);
    }
}
export const generateDocument = (data) => async () => {
    try {
        return await http.post('generateDocument', data);
    } catch (e) {
        getError(e);
        return e;
    }
}
export const confirmRenewal = (policyId) => async () => {
    try {
        return await http.post(APIConstant.path.confirmRenewal, {policyId});
    } catch (e) {
        getError(e);
    }
}
export const UnConfirmRenewal = (policyId) => async () => {
    try {
        return await http.post('UnConfirmRenewal', {policyId});
    } catch (e) {
        getError(e);
    }
}
export const cancelPolicy = (data) => async () => {
    try {
        return await http.post(APIConstant.path.cancelPolicy, data);
    } catch (e) {
        getError(e);
        return e;
    }
}
export const getBoatingQualification = (data) => async () => {
    try {
        return await http.post('getBoatingQualification', data);
    } catch (e) {
        getError(e);
    }
}
export const getBinder = (data) => async () => {
    try {
        return await http.post('getBinder', data);
    } catch (e) {
        getError(e);
    }
}
export const getPolicyUnderwritingData = (data) => async () => {
    try {
        return await http.post('getPolicyUnderwritingData', data);
    } catch (e) {
        getError(e);
    }
}
export const showDynamicComputationPopup = (PolicyId, Option) => async () => {
    try {
        return await http.post('showDynamicComputationPopup', {PolicyId, Option});
    } catch (e) {
        getError(e);
    }
}
export const unBindPolicy = (PolicyId) => async () => {
    try {
        return await http.post('unBindPolicy', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const bindPolicy = (data) => async () => {
    try {
        return await http.post('bindPolicy', data);
    } catch (e) {
        getError(e);
    }
}
export const getNextRenewalLabel = (PolicyId) => async () => {
    try {
        const res = await http.post('getNextRenewalLabel', {PolicyId});
        return (res?.success && res?.data.length && res?.data[0].length) ? res.data[0][0] : "";
    } catch (e) {
        getError(e);
    }
}
export const setPremiumPolicyType = (data) => async () => {
    try {
        return await http.post(APIConstant.path.setPremiumPolicyType, data);
    } catch (e) {
        getError(e);
    }
}
export const autoBinderByCountry = (PolicyId, Country) => async () => {
    try {
        return await http.post(APIConstant.path.autoBinderByCountry, {PolicyId, Country});
    } catch (e) {
        getError(e);
    }
}
export const getUserPolicyList = (id, entityType) => async () => {
    try {
        return await http.post('getUserPolicyList', {id, entityType});
    } catch (e) {
        getError(e);
    }
}
export const getPolicyClaimCount = (id) => async () => {
    try {
        return await http.post('getPolicyClaimCount', {id});
    } catch (e) {
        getError(e);
    }
}
export const changePolicyData = (data) => async () => {
    try {
        return await http.post('changePolicyData', data);
    } catch (e) {
        getError(e);
    }
}
export const getWaterSkiiAndWaterToys = (LanguageId) => async () => {
    try {
        return await http.post('getWaterSkiiAndWaterToys', {LanguageId});
    } catch (e) {
        getError(e);
    }
}
export const getUnderwriterAmount = (data) => async () => {
    try {
        return await http.post('getUnderwriterAmount', data);
    } catch (e) {
        getError(e);
    }
}
export const getOrganazationData = (ContactType) => async () => {
    try {
        return await http.post('getOrganazationData', {ContactType});
    } catch (e) {
        getError(e);
    }
}
export const autoAssignBinder = (PolicyId) => async () => {
    try {
        return await http.post('autoAssignBinder', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const updateLastBindDate = (data) => async () => {
    try {
        return await http.post('updateLastBindDate', data);
    } catch (e) {
        getError(e);
    }
}
export const addEditSumInsured = (data) => async () => {
    try {
        const res = await http.post('addEditSumInsured', data);
        return (res?.success) ? true : res.data.returnValue;
    } catch (e) {
        return getError(e);
    }
}
export const addEditCompFreezeDate = (data) => async () => {
    try {
        const res = await http.post('addEditCompFreezeDate', data);
        return (res?.success) ? true : res.data.returnValue;
    } catch (e) {
        return getError(e);
    }
}
export const getVesselTypeByCountry = (data) => async () => {
    try {
        return await http.post(APIConstant.path.getVesselTypeByCountry, data);
    } catch (e) {
        getError(e);
    }
}
export const getMooringGuidId = (MooringType, MooringName) => async () => {
    try {
        return await http.post('getMooringGuidId', {MooringType, MooringName});
    } catch (e) {
        getError(e);
    }
}

export const isreviewInNextRenewal = (data) => async () => {
    try {
        return await http.post('isreviewInNextRenewal', data);
    } catch (e) {
        getError(e);
    }
}
export const alterReferralFlag = (data) => async () => {
    try {
        return await http.post('alterReferralFlag', data);
    } catch (e) {
        getError(e);
    }
}
export const isPolicyRecomputationAllowed = (PolicyId) => async () => {
    try {
        const res = await http.post('isPolicyRecomputationAllowed', { PolicyId });
        if (res?.success) {
            return res.data;
        }
    } catch (e) {
        getError(e);
    }
}
export const checkBinder = (PolicyId,BinderId) => async () => {
    try {
        return await http.post('checkBinder', {PolicyId,BinderId});
    } catch (e) {
        getError(e);
    }
}

export const manullyUpdateBinder = (data) => async () => {
    try {
        return await http.post('manullyUpdateBinder', data);
    } catch (e) {
        getError(e);
    }
}