import {dispatchAction, getError} from "../../utils/common";
import http from '../../utils/Interceptor';
import APIConstant from '../../utils/constants';

export const SET_DATA = '[Policy SidebarNav] Set Redux Data';

export const getClaimPaymentInfoByClaimId = (data) => async () => {
    try {
        const res = await http.post('getClaimPaymentInfoByClaimId', data);
        if (res?.success) {
            return res.data;
        } else {
            return false;
        }
    } catch (e) {
        getError(e);
        return false;
    }
}
export const attachmentDocuments = (data) => async () => {
    try {
        const res = await http.post('getAttachmentDocuments', data);
        if (res?.success) {
            return res.data;
        } else {
            return false;
        }
    } catch (e) {
        getError(e);
        return false;
    }
}

export const getEmailTypeDropDown = () => async (dispatch) => {
    try {
        const res = await http.post('getEmailTypeDropDown');
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, { prop: 'emailTypeList', data: res.data })
        }
    } catch (e) {
        getError(e);
    }
}

export const getSMSTypeDropDown = () => async (dispatch) => {
    try {
        const res = await http.post('getSMSTypeDropDown');
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, { prop: 'smsTypeList', data: res.data })
        }
    } catch (e) {
        getError(e);
    }
}
export const getDataForEmailSMS = (data) => async (dispatch) => {
    try {
        //before call API clean existing data, so if we can't find details then it will show empty result
        dispatchAction(dispatch, SET_DATA, { prop: 'emailSmsData', data: {} })
        const res = await http.post('getDataForEmailSMS',data);
        if (res?.success && res.data.length) {
            dispatchAction(dispatch, SET_DATA, { prop: 'emailSmsData', data: res.data[0] })
            return res.data[0]
        }
    } catch (e) {
        getError(e);
    }
}
export const getSanctionSearchResultDetails = (data) => async (dispatch) => {
    try {
        //before call API set default data, so if we can't find details then it will show empty result
        dispatchAction(dispatch, SET_DATA, { prop: 'sanctionSearch', data: { SearchResult: 'Pending', DocumentPath: '', DocumentName: '' } })
        const res = await http.post(APIConstant.path.getSanctionSearchResultDetails,data);
        if (res?.success && res.data.length) {
            dispatchAction(dispatch, SET_DATA, { prop: 'sanctionSearch', data: res.data[0] })
        }
    } catch (e) {
        getError(e);
    }
}
export const getClaimEmailTypeDropDown = () => async (dispatch) => {
    try {
        const res = await http.post('getClaimEmailTypeDropDown');
        if (res?.success) {
            dispatchAction(dispatch, SET_DATA, { prop: 'claimEmailTypeList', data: res.data })
        }
    } catch (e) {
        getError(e);
    }
}

export const addNotes = (NotesForm) => async () => {
    try {
        return await http.post('addNotes',NotesForm);
    } catch (e) {
        getError(e);
    }
}

export const getPolicyStageDetails = (PolicyId) => async () => {
    try {
        const res = await http.post('getPolicyStageDetails',{PolicyId});
        return (res?.success)? res.data : '';
    } catch (e) {
        getError(e);
    }
}

export const getDocumentByDocumentId = (DocumentId) => async () => {
    try {
        return await http.post('getDocumentByDocumentId',{DocumentId});
    } catch (e) {
        getError(e);
    }
}

export const getpolicyNumberById = (policyId) => async () => {
    try {
        return await http.post('getpolicyNumberById',{policyId});
    } catch (e) {
        getError(e);
    }
}

export const getDocumentListByTypeAndId = (data) => async () => {
    try {
        return await http.post('getDocumentListByTypeAndId',data);
    } catch (e) {
        getError(e);
    }
}

export const sendEmailOnDemand = (data) => async () => {
    try {
        return await http.post('sendEmailOnDemand',data);
    } catch (e) {
        return getError(e);
    }
}

export const sendSMSOnDemand = (data) => async () => {
    try {
        return await http.post('sendSMSOnDemand',data);
    } catch (e) {
        return getError(e);
    }
}

export const sendCustomEmail = (data) => async () => {
    try {
        return await http.post('sendCustomEmail',data);
    } catch (e) {
        return getError(e);
    }
}

export const updateCustomEmail = (data) => async () => {
    try {
        return await http.post('updateCustomEmail',data);
    } catch (e) {
        return getError(e);
    }
}

export const getDocusignEnvelopeListByPolicyId = (data) => async () => {
    try {
        return await http.post('getDocusignEnvelopeListByPolicyId',data);
    } catch (e) {
        getError(e);
    }
}

export const getgeneratebulkDocument = () => async () => {
    try {
        return await http.post('getgeneratebulkDocument');
    } catch (e) {
        getError(e);
    }
}

export const bulkdocumentrecompute = (data) => async () => {
    try {
        return await http.post('bulkdocumentrecompute',data);
    } catch (e) {
        getError(e);
    }
}

export const addDocusignAttachment = (AttachmentDocuments) => async () => {
    try {
        return await http.post('addDocusignAttachment',{AttachmentDocuments});
    } catch (e) {
        getError(e);
    }
}
