import http from '../../../utils/Interceptor'
import {dispatchAction, getError} from "../../../utils/common";
import APIConstant from '../../../utils/constants';
import {CLEAR_DATA as clearCommonSelect} from '../../CommonSelect/action'
import {CLEAR_DASHBOARD, SET_USER_INFO} from '../../Dashboard/action'

export const SET_DATA = '[Auth] Set Redux Data';

export const loginUser = (username, password) => async (dispatch) => {
    try {
        const res = await http.post(APIConstant.path.login, {username, password});
        if (res.success) {
            dispatch(getSystemConfigByKey('PolicyDetailPageTimer'))
            dispatch(getSystemConfigByKey('PolicyDetailModalTimer'))
            localStorage.setItem('access_token', res.data.token)
            localStorage.setItem('loggedInUserData', JSON.stringify(res.data))
            const teamType = res.data.SecurityGroupName === "Underwriting" && res.data.SuperUser !== 1 ? "New Business"
                : res.data.SecurityGroupName === "Marine Trade" ? "Renewal" : res.data.SecurityGroupName;
            dispatchAction(dispatch, SET_USER_INFO, { data: {
                    securityGroup: res.data.SecurityGroupName,
                    superUser: res.data.SuperUser === 1,
                    staffId: res.data.SuperUser === 1 ? "" : res.data.EntityId,
                    teamType
                }});
            dispatchAction(dispatch, SET_DATA, {prop: 'authUser', data: res.data});
            return res.data;
        } else {
            return res.message;
        }
    } catch (e) {
        return getError(e);
    }
}

export const logoutUser = () => async (dispatch) => {
    try {
        dispatchAction(dispatch, clearCommonSelect);
        dispatchAction(dispatch, CLEAR_DASHBOARD);
        localStorage.clear()
        dispatchAction(dispatch, SET_DATA, {prop: 'authUser', data: {}});
    } catch (e) {
        getError(e);
    }
}

export const releaseLock = (data) => async () => {
    try {
        return await http.post('releaseLock', data);
    } catch (e) {
        getError(e);
    }
}

export const changePassword = (data) => async () => {
    try {
        return await http.post('auth/changePassword', data);
    } catch (e) {
        getError(e);
    }
}
export const getSystemConfigByKey = (key) => async () => {
    let configValue = 0;
    try {
        const res = await http.post('getSystemConfigByKey', {key});
        configValue = (res.success && res.data.length) ? res.data[0].SysValue : 0;
    } catch (e) {
        getError(e);
    }
    localStorage.setItem(key, configValue);
}