import {getError} from "../../utils/common";
import http from '../../utils/Interceptor';

export const SET_DATA = '[TeamWorkFLow] Set Redux Data';

export const dropdownByCodeForReason = (StingMapKey) => async () => {
    try {
        return await http.post('dropdownByCodeForReason', {StingMapKey});
    } catch (e) {
        getError(e);
    }
}
export const getRenewalConfirmedStats = (data) => async () => {
    try {
        const res = await http.post('getRenewalConfirmedStats', data);
        return (res.success) ? res.data[0][0] : "";
    } catch (e) {
        getError(e);
    }
}

export const setLock = (PolicyId) => async () => {
    try {
        return await http.post('setLock', {PolicyId});
    } catch (e) {
        getError(e);
    }
}

export const releaseLock = (PolicyId, ReleaseAll) => async () => {
    try {
        return await http.post('releaseLock', {PolicyId, ReleaseAll});
    } catch (e) {
        getError(e);
    }
}

export const updateAssignedRole = (data) => async () => {
    try {
        return await http.post('updateAssignedRole', data);
    } catch (e) {
        getError(e);
    }
}

export const changePolicyHandler = (PolicyId, PersonId) => async () => {
    try {
        return await http.post('changePolicyHandler', {PolicyId, PersonId});
    } catch (e) {
        getError(e);
    }
}

export const assignPolicy = (data) => async () => {
    try {
        return await http.post('assignPolicy', data);
    } catch (e) {
        getError(e);
    }
}

export const getPolicyListByStage = (data) => async () => {
    try {
        return await http.post('getPolicyListByStage', data);
    } catch (e) {
        getError(e);
    }
}