import {SET_DATA} from "./action";

const defaultValue = {
    referrList: [],
    claimDetail: {},
    policyDetail: {},
    policyHolder: [],
    surveyerNameArr: [],
    statusBackgroundColor: [
        {
            label: "Open",
            value: "btn btn-success",
        },
        {
            label: "Closed",
            value: "btn btn-danger",
        },
        {
            label: "Pending",
            value: "btn btn-warning",
        },
        {
            label: "Withdrawn",
            value: "btn btn-danger",
        },
        {
            label: "Claim Approved",
            value: "btn btn-success",
        },
        {
            label: "Declined",
            value: "btn btn-danger",
        },
        {
            label: "Decline",
            value: "btn btn-danger",
        },
        {
            label: "Underwriting",
            value: "btn btn-success",
        },
        {
            label: "Complaint",
            value: "btn btn-info",
        },
        {
            label: "Accounts",
            value: "btn btn-success",
        },
    ]
};

const reducer = (state = defaultValue, action = []) => {
    const { type, payload } = action;
    switch (type) {
        case SET_DATA:
            return {
                ...state,
                [payload.prop]: payload.data
            };
        default:
            return state;
    }
};

export default reducer;