import {getError} from "../../../utils/common";
import http from '../../../utils/Interceptor';

export const SET_DATA = '[AddUpdateBinder] Set Redux Data';

export const getBinderDataById = (binderId) => async () => {
    try {
        const res = await http.post('getBinderDataById',{binderId});
        return (res.success && res.data.length) ? res.data[0] :"" ;
    } catch (e) {
        getError(e);
    }
}
export const makeCloneBinder = (data) => async () => {
    try {
        return await http.post('makeCloneBinder',data);
    } catch (e) {
        getError(e);
    }
}

export const bulkInsertBinderRatingBands = (data) => async () => {
    try {
        return await http.post('bulkInsertBinderRatingBands',data);
    } catch (e) {
        getError(e);
    }
}

export const insertRatingBandsFromDetail = (data) => async () => {
    try {
        return await http.post('insertRatingBandsFromDetail',data);
    } catch (e) {
        getError(e);
    }
}

export const deleteRatingBinder = (data) => async () => {
    try {
        return await http.post('deleteBinderRatingBands',data);
    } catch (e) {
        getError(e);
    }
}

export const getBinderRatingBinder = (data) => async () => {
    try {
        return await http.post('getBinderRatingBands',data);
    } catch (e) {
        getError(e);
    }
}

export const getBounderRisk = (BinderId) => async () => {
    try {
        return await http.post('getBounderRisk',{BinderId});
    } catch (e) {
        getError(e);
    }
}

export const getAuthorizeClass = (BinderId) => async () => {
    try {
        return await http.post('getAuthorizeClass',{BinderId});
    } catch (e) {
        getError(e);
    }
}

export const addUpdateBinderRatingBands = (data) => async () => {
    try {
        return await http.post('addUpdateBinderRatingBands',data);
    } catch (e) {
       return getError(e);
    }
}

export const getSettlement = (BinderId) => async () => {
    try {
        return await http.post('getSettlement',{BinderId});
    } catch (e) {
        getError(e);
    }
}
export const getTransaction = (BinderId) => async () => {
    try {
        return await http.post('getTransaction',{BinderId});
    } catch (e) {
        getError(e);
    }
}

export const getBinderDetails = (BinderType) => async () => {
    try {
        return await http.post('getBinderDetails',{BinderType});
    } catch (e) {
        getError(e);
    }
}

export const deleteBinder = (BinderId) => async () => {
    try {
        const res = await http.post('deleteBinder',{BinderId});
        return (res.success && res?.data.length) ? res.data[0][0] : "";
    } catch (e) {
        getError(e);
    }
}

export const makeBinderActive = (BinderId) => async () => {
    try {
        const res = await http.post('makeBinderActive',{BinderId});
        return (res.success && res?.data.length) ? res.data[0][0] : "";
    } catch (e) {
        getError(e);
    }
}
export const GetSelectedAuthorizeGroup  = (BinderId) => async () => {
    try {
       return await http.post('getselectedAuthorizeGroup',{BinderId});
    } catch (e) {
        getError(e);
    }
}
export const getNextBinderList = (data) => async () => {
    try {
        return await http.post('getNextBinderList',data);
    } catch (e) {
        getError(e);
    }
}