import axios from "axios";
import {logoutUser, SET_DATA} from "../views/Pages/Login/action";
import {apiBase} from './config';
import i18n from "i18next";

let apiUrls = [];
const noLoaderUrls = [
    'addUpdateTags',
    'generatebulkDocument',
    'getgeneratebulkDocument',
]
const fileUplaodUrls = ['bulkInsertBinderRatingBands','addContactDetails','uploadDocument','addNotes']
export const http = axios.create({
    baseURL: `${apiBase}api/`,
    //timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'accept-language': i18n.language,
        "origin2": 'FE'
    }
});

//add or remove spinner
const manageLoader = (url, state = 'remove') => {
    //set loader: check should loader display or not?
    if (url && state === 'add' && !noLoaderUrls.includes(url)) {
        //if existing api call is not ongoing then and only then show loader
        if (!apiUrls.length) {
            document.querySelector('.spinner').classList.add('spinner-show');
            document.querySelector('.logo-img').classList.add('spinner-show');
        }
        //add api url in array
        apiUrls.push(url)
    } else if (url && state === 'remove') {
        //Remove loader: find response url from array and if exist then remove it
        const apiIndex = apiUrls.indexOf(url);
        if (apiIndex !== -1) {
            apiUrls.splice(apiIndex, 1)
            //if all request is completed then remove loader
            if (!apiUrls.length) {
                document.querySelector('.spinner').classList.remove('spinner-show');
                document.querySelector('.logo-img').classList.remove('spinner-show');
            }
        }
    }
}

const requestHandler = (config, store) => {
    const {auth: {authUser}} = store.getState();
    if (config.data instanceof FormData && fileUplaodUrls.includes(config.url)) {
        config.headers = {...config.headers, "Content-Type": `'multipart/form-data'`}
    }

    if (authUser?.PersonId) {
        //add user and token data to request header
        config.headers = {...config.headers, token: `${authUser.token}`}
        manageLoader(config.url, 'add')
    }
    return config;
};

const responseHandler = (response) => {
    manageLoader(response.config.url)
    return response?.data;
};

const errorHandler = (error, store) => {
    //console.log('check ', error, error?.config?.url);
    manageLoader(error?.config?.url)
    if (error.response?.status === 403) {
        store.dispatch(logoutUser());
        return Promise.reject(error);
    } else if (error.response?.status === 401) {
        const originalRequest = error.config;
        const {auth: {authUser}} = store.getState();
        if (authUser.refreshToken && !originalRequest._retry) {
            originalRequest._retry = true;
            return http.get(`auth/refresh_token`, {headers: {'refresh-token': authUser.refreshToken}})
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.setItem('access_token', res.data.token)
                        const newUserInfo = {...authUser, refreshToken: res.data.refreshToken, token: res.data.token}
                        localStorage.setItem('loggedInUserData', JSON.stringify(newUserInfo))
                        store.dispatch({type: SET_DATA, payload: {data: newUserInfo, prop: 'authUser'}})
                        originalRequest.data = JSON.parse(originalRequest.data)
                        return http(originalRequest);
                    }
                });
        }
        return Promise.reject(error);
    }
    return Promise.reject(error?.response?.data || error);
};

export const interceptor = (store) => {
    http.interceptors.request.use(
        (request) => requestHandler(request, store),
        (error) => errorHandler(error, store)
    );

    http.interceptors.response.use(
        (response) => responseHandler(response),
        (error) => errorHandler(error, store)
    );
}
export default http;