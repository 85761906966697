import React, {Component} from 'react';
import {getError} from "./utils/common";
import http from './utils/Interceptor';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: "", errorInfo: "" };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        try {
            //console.log(error.message,errorInfo)
            this.setState({ errorMessage: error.message, errorInfo:errorInfo });
            http.post('auth/FrontEndError', {error: error.message, errorInfo});
        } catch (e) {
            getError(e);
        }
        //alert("we are have found some issue in code and we have log it. Please click OK and refresh the page if it's stuck.");
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <><h3>Something went wrong. We have log the error, please take a screenshot of following error and sent it to us.</h3>;
                <br/><br/>{this.state.errorMessage}<br/><br/>
                {this.state.errorInfo}
            </>
            //return this.props.children;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;