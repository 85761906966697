import {dispatchAction, getError} from "../../utils/common";
import _ from "lodash";
import http from '../../utils/Interceptor';

export const SET_DATA = '[CommonSelect] Set Redux Data';
export const STRING_MAP = '[CommonSelect] String Map';
export const CLEAR_DATA = '[CommonSelect] clear select box';

export const getSelectBoxData = () => async (dispatch, getState) => {
    try {
        if (!Object.keys(getState()?.commonSelect?.dd).length) {
            const res = await http.post('getSelectBox');
            if (res.data.length) {
                dispatchAction(dispatch, STRING_MAP, res.data);
                return true;
            }
            return false;
        }
    } catch (e) {
        getError(e);
        return false
    }
}

export const getSelectBox = (stringMapType, setStateName, order = "StringMapName", language = "", force = false) => async (dispatch, getState) => {
    try {
        if (!force && getState()?.commonSelect[stringMapType]?.length) {
            return getState().commonSelect[stringMapType];
        }
        //console.log(getState()?.commonSelect?.dd)
        const tempLang = getState()?.auth?.authUser?.PreferredLanguage;
        if(language.toLowerCase() === 'gb' || language.toLowerCase() === 'en-gb'){
            language = 'en-ie'
        }
        const prefLang = (language) ? language.toLowerCase() : (tempLang ? tempLang.toLowerCase() : 'en-ie');
        const data = getState()?.commonSelect?.dd[stringMapType]
        let finalData = (data && data[prefLang]) ? data[prefLang] : [];
        if (finalData.length && order !== "StringMapName") {
            finalData = _.sortBy(finalData, [(o) => o[order]]);
        }
        dispatchAction(dispatch, SET_DATA, {prop: setStateName, data: finalData});
        return finalData;

    } catch (e) {
        getError(e);
    }
}

export const getCurrencyData = () => async (dispatch, getState) => {
    try {
        if (!getState()?.commonSelect?.currencyList.length) {
            const res = await http.get('getCurrency');
            if (res?.success) {
                dispatchAction(dispatch, SET_DATA, {prop: 'currencyList', data: res.data});
            }
            return res.data;
        }
        return getState()?.commonSelect?.currencyList;
    } catch (e) {
        getError(e);
    }
}

export const getLanguageData = () => async (dispatch, getState) => {
    try {
        if (!getState()?.commonSelect?.languageList.length) {
            const res = await http.get('getLanguage');
            if (res?.success) {
                dispatchAction(dispatch, SET_DATA, {prop: 'languageList', data: res.data});
            }
        }
        return getState()?.commonSelect?.languageList;
    } catch (e) {
        getError(e);
    }
}

export const getCountryData = () => async (dispatch, getState) => {
    try {
        if (!getState()?.commonSelect?.countryDataList.length) {
            const res = await http.get('getCountry');
            if (res?.success) {
                dispatchAction(dispatch, SET_DATA, {prop: 'countryDataList', data: res.data});
            }
        }
        return getState()?.commonSelect?.countryDataList;
    } catch (e) {
        getError(e);
    }
}
export const releaseLock = (data) => async () => {
    try {
        return await http.post('releaseLock', data);
    } catch (e) {
        getError(e);
    }
}
