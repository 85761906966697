import {SET_DATA as finantialAction} from "./actionFinancial";
import {SET_DATA as otherAction} from "./actionOther";
import {SET_DATA as followupAction} from "./actionFollowup";

const defaultValue = {
    smsTypeList : [],
    emailTypeList :[]
};

const reducer = (state = defaultValue, action = []) => {
    const { type, payload } = action;
    switch (type) {
        case finantialAction:
            return {
                ...state,
                [payload.prop]: payload.data
            };
            case otherAction:
                return {
                    ...state,
                    [payload.prop]: payload.data
                };
            case followupAction:
                return {
                    ...state,
                    [payload.prop]: payload.data
                };
        default:
            return state;
    }
};

export default reducer;