import {dispatchAction, getError} from "../../utils/common";
import APIConstant, {CommonConfig} from '../../utils/constants';
import http from '../../utils/Interceptor';
import _ from 'lodash'

export const SET_DATA = '[Dashboard] Set Redux Data';
export const SET_DASH_DATA = '[Dashboard] Set Data';
export const SET_INFO = '[Dashboard] Set Info';
export const SET_POLICY_LIST = '[Dashboard] Set Stage Wise Policy List';
export const SET_CLAIM_LIST = '[Dashboard] Set Claim List';
export const APPEND_DATA = '[APPEND_DATA] Append data';
export const SET_USER_INFO = '[SET_USER_INFO] Set user data';
export const SET_NOTES_DATA = '[SET_NOTES_DATA] Set notes data';
export const SET_COUNTS = '[SET_COUNTS] Set counts';
export const CLEAR_DASHBOARD = '[CLEAR_DASHBOARD] Clear on logout';
export const securityGroupName = CommonConfig.loggedInUserData().SecurityGroupName;

const getSg = (getState) => securityGroupName ? securityGroupName : getState().dashboard.securityGroup;

export const setStateValue = (data, prop) => (dispatch) => {
    try {
        dispatchAction(dispatch, SET_DATA, {prop, data});
    } catch (e) {
        getError(e);
    }
}

export const getPolicyListByStage = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.policyListByStage[data?.PolicyStage]?.length;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].policyListByStage[data.PolicyStage]
        }
        const res = await http.post('getPolicyListByStage', data);
        if (res.success) {
            dispatchAction(dispatch, SET_POLICY_LIST, {
                prop: data.PolicyStage,
                data: res.data,
                sg: sg
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const GetClaimReferredList = (data, refresh = false) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard?.info[sg]?.referredClaims?.length;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].referredClaims
        }
        let res = {};
        if (data) {
            res = await http.post('GetClaimReferredList', data);
        } else {
            res = await http.post('GetClaimReferredList');
        }
        if (res.success) {
            dispatchAction(dispatch, SET_INFO, {
                prop: "referredClaims",
                data: res?.data?.length ? res.data[0] : []
            });
            dispatchAction(dispatch, SET_COUNTS, {
                prop: "referredClaimsCount",
                data: res?.data?.length ? res.data[0].length : 0
            });
            dispatchAction(dispatch, SET_COUNTS, {
                prop: "uwReviewOverCount",
                data: (res?.data?.length && res?.data[1]?.length) ? res.data[1][0].Count : 0
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const getReferredClaimCount = (data, refresh = false) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.counts?.referredClaimsCount;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].counts.referredClaimsCount
        }
        const res = await http.post('getReferredClaimCount', data);
        if (res.success) {
            dispatchAction(dispatch, SET_COUNTS, {
                prop: "referredClaimsCount",
                data: res?.data ? res?.data.Count : 0
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const getDashboardRenewalFollowupList = (isMarineTrade = 0, refresh = false) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.renewalFollowupList?.length;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].renewalFollowupList
        }
        const res = await http.post('getDashboardRenewalFollowupList', {isMarineTrade});
        if (res.success) {
            dispatchAction(dispatch, SET_INFO, {
                prop: "renewalFollowupList",
                data: res?.data.length ? res.data[0] : []
            });
            return res?.data.length ? res.data[0] : []
        }
    } catch (e) {
        getError(e);
    }
}
export const getClaimNotes = (data, refresh = false) => async (dispatch, getState) => {
    try {
        dispatch(setStateValue({...data, showClaim: true}, 'showNotesModal'))
        const isExist = getState()?.dashboard?.noteList[data.entityType][data.entityId]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard?.noteList[data.entityType][data.entityId]
        }
        const res = await http.post('getClaimNotes', {entityType: data.entityType, entityId: data.entityId});
        if (res.success) {
            dispatchAction(dispatch, SET_NOTES_DATA, {
                prop: data.entityType,
                entityId: data.entityId,
                data: res.data
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const getNewBusineesNotes = (data, refresh = false) => async (dispatch, getState) => {
    try {
        dispatch(setStateValue({...data, show: true}, 'showNotesModal'))
        const isExist = getState()?.dashboard?.noteList[data.entityType][data.entityId]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard?.noteList[data.entityType][data.entityId]
        }
        const res = await http.post('getNewBusineesNotes', {entityType: data.entityType, entityId: data.entityId});
        if (res.success) {
            dispatchAction(dispatch, SET_NOTES_DATA, {
                prop: data.entityType,
                entityId: data.entityId,
                data: res.data
            });
        }
    } catch (e) {
        getError(e);
    }
}
export const assignPolicy = (data) => async () => {
    try {
        return await http.post('assignPolicy', data);
    } catch (e) {
        getError(e);
    }
}
export const updateAssignedRole = (data) => async () => {
    try {
        return await http.post('updateAssignedRole', data);
    } catch (e) {
        getError(e);
    }
}
export const changePolicyHandler = (data) => async () => {
    try {
        return await http.post('changePolicyHandler', data);
    } catch (e) {
        getError(e);
    }
}

export const addNotes = (data) => async () => {
    try {
        return await http.post('addNotes', data);
    } catch (e) {
        getError(e);
    }
}
export const updatePolicyStageByNote = (PolicyId) => async () => {
    try {
        return await http.post('updatePolicyStageByNote', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const saveFollowUpNote = (data) => async () => {
    try {
        return await http.post('saveFollowUpNote', data);
    } catch (e) {
        getError(e);
    }
}
export const GetClaimMonthsDataByUser = (data) => async () => {
    try {
        return await http.post('GetClaimMonthsDataByUser', data);
    } catch (e) {
        getError(e);
    }
}
export const getSpGetUsersByTeam = (TeamName, refresh) => async (dispatch, getState) => {
    try {
        const isExist = getState()?.dashboard.usersByTeam[TeamName]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.usersByTeam[TeamName]
        }
        const res = await http.post('getSpGetUsersByTeam', {TeamName});
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, APPEND_DATA, {
                prop: 'usersByTeam',
                data: {[TeamName]: res.data}
            });
            return res.data
        }
    } catch (e) {
        getError(e);
    }
}
export const getAssigneDetails = (PolicyId) => async () => {
    try {
        return await http.post('getAssigneDetails', {PolicyId});
    } catch (e) {
        getError(e);
    }
}
export const addClaimNotes = (data) => async () => {
    try {
        return await http.post('addClaimNotes', data);
    } catch (e) {
        getError(e);
    }
}
export const getRenewalConfirmedData = (data) => async () => {
    try {
        return await http.post('getRenewalConfirmedData', data);
    } catch (e) {
        getError(e);
    }
}
export const savePolicyStage = (data) => async () => {
    try {
        return await http.post('savePolicyStage', data);
    } catch (e) {
        getError(e);
    }
}
export const GetPolicyMonthsDataByUser = (data) => async () => {
    try {
        return await http.post('GetPolicyMonthsDataByUser', data);
    } catch (e) {
        getError(e);
    }
}
export const getLastPoliciesWorkedList = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.lastWorkedList?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.lastWorkedList
        }
        const res = await http.post('getLastPoliciesWorkedList', data);
        if (res.success) {
            dispatchAction(dispatch, SET_INFO, {
                prop: "lastWorkedList",
                data: res?.data?.length ? res.data : []
            });
            return res?.data?.length ? res.data : [];
        }
    } catch (e) {
        getError(e);
    }
}

export const GetPolicyMonthsCountByUser = (Year, refresh) => async (dispatch, getState) => {
    try {
        const isExist = getState()?.dashboard?.policyStatisticsBar[Year];
        if (isExist && !refresh) {
            return getState()?.dashboard.policyStatisticsBar[Year]
        }
        const res = await http.post('GetPolicyMonthsCountByUser', {Year});
        if (res.success) {
            const retData = {
                CountNewBusiness: _.map(res.data[0], 'CountNewBusiness'),
                CountRenewal: _.map(res.data[1], 'CountRenewal'),
                CountLapsed: _.map(res.data[2], 'CountLapsed'),
            }
            dispatchAction(dispatch, SET_DATA, {
                prop: "policyStatisticsBar",
                data: {[Year]: retData}
            });
            return retData
        }
    } catch (e) {
        getError(e);
    }
}
export const getPolicyStageCount = (data) => async (dispatch) => {
    try {
        const res = await http.post('getPolicyStageCount', data);
        if (res.success) {
            dispatchAction(dispatch, SET_DATA, {prop: "policyStageCount", data: res.data});
        }
        return res;
    } catch (e) {
        getError(e);
    }
}
export const getPolicyLeadCountList = (data) => async () => {
    try {
        return await http.post('getPolicyLeadCountList', data);
    } catch (e) {
        getError(e);
    }
}
export const generateLeaderBoardReport = (data) => async () => {
    try {
        return await http.post('generateLeaderBoardReport', data);
    } catch (e) {
        getError(e);
    }
}
// For Throughtput Report
export const generateThroughtputLeaderBoardReport = (data) => async () => {
    try {
        return await http.post('generateThroughtputLeaderBoardReport', data);
    } catch (e) {
        getError(e);
    }
}
export const generateStatisticsReport = (Month, Year) => async (dispatch) => {
    try {
        const res = await http.post('generateStatisticsReport', {Month, Year});
        if (res.success) {
            const retData = {
                NewBusinessCount: res.data[0][0].NewBusinessCount,
                RenewalCount: res.data[1][0].RenewalCount,
                vTotalPremiumNB: res.data[2][0].vTotalPremiumNB,
                vTotalPremiumR: res.data[3][0].vTotalPremiumR,
            }
            dispatchAction(dispatch, SET_DATA, {
                prop: "policyStatistics",
                data: retData
            });
            return retData
        }
    } catch (e) {
        getError(e);
    }
}
export const generatePieChartReport = (data) => async () => {
    try {
        return await http.post('generatePieChartReport', data);
    } catch (e) {
        getError(e);
    }
}
export const getRenewalConfirmedCount = (data) => async () => {
    try {
        return await http.post('getRenewalConfirmedCount', data);
    } catch (e) {
        getError(e);
    }
}

export const getNotificationList = (EntityType, refresh) => async (dispatch, getState) => {
    try {
        const isExist = getState().dashboard.notifications?.notifications?.length && getState().dashboard.notifications?.reminders?.length;
        if (isExist && !refresh) {
            return getState().dashboard.notifications
        }
        const res = await http.post('getNotificationList', {EntityType});
        if (res.success && res?.data?.length) {
            const notifications = _.filter(res.data, (e) => e.NotificationType !== 'Followup Reminder' && e.NotificationType !== 'Reminder');
            const reminders = _.filter(res.data, (e) => e.NotificationType === 'Followup Reminder' || e.NotificationType === 'Reminder');
            dispatchAction(dispatch, SET_DATA, {
                prop: "notifications",
                data: {
                    notifications: notifications,
                    reminders: reminders
                }
            });
        }
    } catch (e) {
        getError(e);
    }
}
export const updateNotification = (notes, type) => async (dispatch) => {
    try {
        let data = {
            NotificationId: notes.DashboardNotificationId,
            EntityId: notes.EntityId,
            NotificationText: notes.NotificationText,
            Type: type,
            IsRead: notes.IsRead.data[0] !== 1,
        };
        const res = await http.post('updateNotification', data);
        if (res?.success) {
            dispatch(getNotificationList("All", true));
        }
    } catch (e) {
        getError(e);
    }
}
export const getLeadPolicyListByStage = (data) => async () => {
    try {
        return await http.post('getLeadPolicyListByStage', data);
    } catch (e) {
        getError(e);
    }
}
export const getMonthWiseReport = (data) => async () => {
    try {
        return await http.post('getMonthWiseReport', data);
    } catch (e) {
        getError(e);
    }
}
// For Throughput Report
export const getMonthWiseThroughputReport = (data) => async () => {
    try {
        return await http.post('getMonthWiseThroughputReport', data);
    } catch (e) {
        getError(e);
    }
}
export const getStaffMemberList = (data, refresh) => async (dispatch, getState) => {
    try {
        const isExist = getState()?.dashboard?.staffMemberList.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.staffMemberList
        }
        const res = await http.post('getStaffMemberList', data);
        if (res.success) {
            dispatchAction(dispatch, SET_DATA, {
                prop: "staffMemberList",
                data: res.data
            });
            return res.data
        }
    } catch (e) {
        getError(e);
    }
}
export const releaseLock = (data) => async () => {
    try {
        return await http.post('releaseLock', data);
    } catch (e) {
        getError(e);
    }
}
export const dropdownbycodeforreason = (StingMapKey, refresh = false) => async (dispatch, getState) => {
    try {
        const isExist = getState()?.dashboard.notesReason[StingMapKey]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.notesReason[StingMapKey]
        }
        const res = await http.post(APIConstant.path.dropdownbycodeforreason, {StingMapKey});
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, APPEND_DATA, {
                prop: 'notesReason',
                data: {[StingMapKey]: res.data[0]}
            });
            return res.data[0]
        }
    } catch (e) {
        getError(e);
    }
}

export const getOpenClaimCount = (refresh = false) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.openClaimFunnel;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].openClaimFunnel
        }
        const res = await http.post('getOpenClaimCount', {StaffId: getState().dashboard.staffId});
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, SET_INFO, {
                prop: "openClaimFunnel",
                data: {chart: res.data[0], count: res.data[1][0]?.TotalClaimCount}
            });
            dispatchAction(dispatch, SET_COUNTS, {
                prop: "openClaimCount",
                data: res.data[1][0]?.TotalClaimCount
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const getTotalOpenClaim = (refresh = false) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState)
        const isExist = getState()?.dashboard.info[sg]?.counts?.openClaimCount;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].counts.openClaimCount
        }
        const res = await http.post('getTotalOpenClaim', {StaffId: getState().dashboard.staffId});

        if (res.success && res?.data.length) {
            dispatchAction(dispatch, SET_COUNTS, {
                prop: "openClaimCount",
                data: res.data[0][0]?.totalOpenClaimCount
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const getUnassignedOverdueCounts = (refresh = false) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState)
        const isExist = getState()?.dashboard.info[sg]?.counts?.unassignedOverdueCounts;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].counts.unassignedOverdueCounts
        }
        const res = await http.post('getUnassignedOverdueCounts', {StaffId: getState().dashboard.staffId});

        if (res.success && res?.data.length) {
            dispatchAction(dispatch, SET_COUNTS, {
                prop: "unassignedOverdueCounts",
                data: res.data[0]
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const GetClaimStatisticsByUser = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.claimStateTbl;
        if (isExist && !refresh) {
            return getState().dashboard.info[sg].claimStateTbl
        }
        const res = await http.post('GetClaimStatisticsByUser', data);
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, SET_INFO, {
                prop: "claimStateTbl",
                data: {
                    NewClaimCount: res.data[0][0]?.NewClaimCount,
                    CurrentReserve: res.data[1][0]?.CurrentReserve,
                    ClaimClosedCount: res.data[2][0]?.ClaimClosedCount,
                    TotalAgreedAmount: res.data[3][0]?.TotalAgreedAmount,
                    PaidOutReserve: res.data[1][0]?.PaidOutReserve
                }
            });
        }
    } catch (e) {
        getError(e);
    }
}

export const GetClaimMonthsCountByUser = (data, refresh) => async (dispatch, getState) => {
    try {
        const isExist = getState()?.dashboard?.claimStatisticsBar[data.Year];
        if (isExist && !refresh) {
            return getState()?.dashboard.claimStatisticsBar[data.Year]
        }
        const res = await http.post('GetClaimMonthsCountByUser', data);
        if (res.success && res?.data?.length) {
            const retData = {
                openClaimCount: _.map(res.data[0], 'openClaimCount'),
                pendingClaimCount: _.map(res.data[1], 'pendingClaimCount'),
                AwaitingCount: _.map(res.data[2], 'AwaitingCount'),
                ClosedClaimCount: _.map(res.data[3], 'ClosedClaimCount'),
                newClaimCount: _.map(res.data[4], 'newClaimCount'),
                paymentRequestCount: _.map(res.data[5], 'paymentRequestCount')
            }
            dispatchAction(dispatch, SET_DATA, {
                prop: "claimStatisticsBar",
                data: {[data.Year]: retData}
            });
            return retData
        }
    } catch (e) {
        getError(e);
    }
}

export const GetClaimFilterView = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const type = (data.PageType) ? data.PageType : "filter";
        const isExist = getState()?.dashboard.info[sg]?.claimList[type]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.claimList[type]
        }
        const api = ['FollowupToday', 'ReserveWithZero', 'OverdueClaims'].includes(type) ? "getlastreview" : "GetClaimFilterView";
        const res = await http.post(api, data);
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, SET_CLAIM_LIST, {
                prop: type,
                data: res.data[0]
            });
            return res.data[0]
        }
    } catch (e) {
        getError(e);
    }
}

export const getLatestReviewByUser = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.claimList['reviewed']?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.claimList['reviewed']
        }

        const res = await http.post('getLatestReviewByUser', data);
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, SET_CLAIM_LIST, {
                prop: 'reviewed',
                data: res.data[0]
            });
            return res.data[0]
        }
    } catch (e) {
        getError(e);
    }
}

export const getClaimStatsByUser = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.claimList['yearWise']?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.claimList['yearWise']
        }

        const res = await http.post('getClaimStatsByUser', data);
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, SET_CLAIM_LIST, {
                prop: 'yearWise',
                data: res.data[0]
            });
            return res.data[0]
        }
    } catch (e) {
        getError(e);
    }
}

export const getClaimByBinderYear = (refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.claimList['binderYear']?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.claimList['binderYear']
        }

        const res = await http.post('getClaimByBinderYear', {StaffId: getState().dashboard.staffId});
        if (res.success && res?.data?.length) {
            let ClaimCount = [];
            res.data[0].map(ClaimData => {
                let temparr1 = [];
                if (ClaimData.TotalClaim === 1) {
                    temparr1.push(0, 1);
                } else if (ClaimData.TotalClaim === 2) {
                    temparr1.push(0, 1, 2);
                } else {
                    let tempsum = 0, sum = 0;
                    temparr1.push(0);
                    while (tempsum <= ClaimData.TotalClaim - 1) {
                        tempsum = tempsum + ClaimData.TotalClaim / 3
                        sum = Math.round(tempsum);
                        temparr1.push(sum);
                    }
                }
                ClaimCount.push({
                    "BinderYear": ClaimData.BinderYear,
                    "TotalClaim": ClaimData.TotalClaim,
                    "openClaim": ClaimData.openClaim,
                    "Interval": temparr1,
                    "currentValueText": ClaimData.BinderYear + " " + ClaimData.openClaim + "/" + ClaimData.TotalClaim
                })
            })
            dispatchAction(dispatch, SET_CLAIM_LIST, {
                prop: 'binderYear',
                data: ClaimCount
            });
            return ClaimCount
        }
    } catch (e) {
        getError(e);
    }
}

export const getClaimListByBinderYear = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.claimList['binderYearList']?.[data.BinderYear]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.claimList['binderYearList'][data.BinderYear]
        }

        const res = await http.post('getClaimListByBinderYear', data);
        if (res.success && res?.data?.length) {
            let currentList = getState()?.dashboard.info[sg]?.claimList['binderYearList'];

            dispatchAction(dispatch, SET_CLAIM_LIST, {
                prop: 'binderYearList',
                data: {...currentList, [data.BinderYear]: res.data[0]}
            });
            return {...currentList, [data.BinderYear]: res.data[0]}
        }
    } catch (e) {
        getError(e);
    }
}

export const getClaimPaymentReportByUser = (DraftStatus, refresh) => async (dispatch, getState) => {
    try {
        const isExist = getState()?.dashboard.claimPayment[DraftStatus]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.claimPayment[DraftStatus]
        }
        const res = await http.post('getClaimPaymentReportByUser', {DraftStatus});
        if (res.success && res?.data?.length) {
            dispatchAction(dispatch, APPEND_DATA, {
                prop: 'claimPayment',
                data: {[DraftStatus]: res.data}
            });
            return res.data
        }
    } catch (e) {
        getError(e);
    }
}
export const getOnlineBusinessList = (StaffId, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.onlineBusinessList?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.onlineBusinessList
        }
        const res = await http.post('getOnlineOverdueQuoteList', {StaffId});
        if (res.success) {
            dispatchAction(dispatch, SET_INFO, {
                prop: "onlineBusinessList",
                data: res.data
            });
            dispatchAction(dispatch, SET_INFO, {
                prop: "unassignedPolicyCount",
                data: _.filter(res.data, (e) => (e.HandledBy === 'Yachtsman ES Website' || e.HandledBy === 'Yachtsman IE Website'))?.length ?? 0
            });
            return res.data
        }
    } catch (e) {
        getError(e);
    }
}
export const getWebsiteOverdueDocusignList = (StaffId, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.websiteOverdueDocusignList?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.websiteOverdueDocusignList
        }
        const res = await http.post('getOverdueDocusignsList', {StaffId});
        if (res.success) {
            dispatchAction(dispatch, SET_INFO, {
                prop: "websiteOverdueDocusignList",
                data: res.data
            });
            dispatchAction(dispatch, SET_INFO, {
                prop: "websiteOverdueDocusignCount",
                data: res.data.length
            });
            return res.data
        }
    } catch (e) {
        getError(e);
    }
}

export const getClaimAccordingCount = (data, refresh) => async (dispatch, getState) => {
    try {
        const sg = await getSg(getState);
        const isExist = getState()?.dashboard.info[sg]?.claimList['openClaimList']?.[data.ClaimCondition]?.length;
        if (isExist && !refresh) {
            return getState()?.dashboard.info[sg]?.claimList['openClaimList'][data.ClaimCondition]
        }

        const res = await http.post('getClaimAccordingCount', data);
        if (res.success && res?.data?.length) {
            let currentList = getState()?.dashboard.info[sg]?.claimList['openClaimList'];

            dispatchAction(dispatch, SET_CLAIM_LIST, {
                prop: 'openClaimList',
                data: {...currentList, [data.ClaimCondition]: res.data[0]}
            });
            return res.data[0]
        }
    } catch (e) {
        getError(e);
    }
}

export const getClaimNotes2 = (data) => async () => {
    try {
        return await http.post('getClaimNotes', data);
    } catch (e) {
        getError(e);
    }
}

export const getNewBusineesNotes2 = (data) => async () => {
    try {
        return await http.post('getNewBusineesNotes', data);
    } catch (e) {
        getError(e);
    }
}
export const getSpGetUsersByTeam2 = (TeamName) => async () => {
    try {
        return await http.post('getSpGetUsersByTeam', {TeamName});
    } catch (e) {
        getError(e);
    }
}

export const getStaffMemberList2 = (data) => async () => {
    try {
        return await http.post('getStaffMemberList', data);
    } catch (e) {
        getError(e);
    }
}
export const getPolicyList = (data) => async () => {
    try {
        return await http.post('getPolicyList', data);
    } catch (e) {
        getError(e);
    }
}
