import {getError} from "../../utils/common";
import http from '../../utils/Interceptor';
import APIConstant from "../../utils/constants";

export const SET_DATA = '[contactManagement] Set Redux Data';

export const getTask_Followers = () => async () => {
    try {
        return await http.post('getTask_Followers',);
    } catch (e) {
        getError(e);
    }
}

export const getAssignee = () => async () => {
    try {
        return await http.post('getAssignee',);
    } catch (e) {
        getError(e);
    }
}

export const dropdownByCode = (data) => async () => {
    try {
        return await http.post(APIConstant.path.dropdownbycode,data);
    } catch (e) {
        getError(e);
    }
}

export const addContactDetails = (data) => async () => {
    try {
        return await http.post('addContactDetails',data);
    } catch (e) {
       return getError(e);
    }
}

export const addUpdateTasks = (data) => async () => {
    try {
        return await http.post('addUpdateTasks',data);
    } catch (e) {
        getError(e);
    }
}


export const getContactDetailsByTypeAndID = (data) => async () => {
    try {
        return await http.post('getContactDetailsByTypeAndID',data);
    } catch (e) {
        getError(e);
    }
}

export const generateContactCode = () => async () => {
    try {
        return await http.get('generateContactCode');
    } catch (e) {
        getError(e);
    }
}

export const getContactList = (data) => async () => {
    try {
        return await http.post('getContactList',data);
    } catch (e) {
        getError(e);
    }
}

export const deleteContact = (data) => async () => {
    try {
        return await http.post('deleteContact',data);
    } catch (e) {
        getError(e);
    }
}

export const getDocumentListByTypeAndId = (data) => async () => {
    try {
        return await http.post('getDocumentListByTypeAndId',data);
    } catch (e) {
        getError(e);
    }
}

export const uploadDocument = (formData) => async () => {
    try {
        return await http.post('uploadDocument',formData);
    } catch (e) {
        getError(e);
    }
}

export const searchContact = (data) => async () => {
    try {
        return await http.post('searchContact',data);
    } catch (e) {
        getError(e);
    }
}

export const getTasks = () => async () => {
    try {
        return await http.post('getTasks',);
    } catch (e) {
        getError(e);
    }
}

export const deleteTasks = (taskId) => async () => {
    try {
        return await http.post('deleteTasks',{taskId});
    } catch (e) {
        getError(e);
    }
}

export const addVerificationDetails = (data) => async () => {
    try {
        return await http.post('addVerificationDetails',data);
    } catch (e) {
        getError(e);
    }
}
