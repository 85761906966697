import React, {lazy, Suspense} from 'react';
import {HashRouter, Navigate, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import { ToastContainer } from 'react-toastify';
import store, {persistor} from "./redux/store";
import ErrorBoundary from './ErrorBoundary';
import routes from "./utils/routes";
import 'react-toastify/dist/ReactToastify.css';
import './assets/style/App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'))
const PublicPage = lazy(() => import('./containers/PublicPage'))
const Login = lazy(() => import('./views/Pages/Login/Login'))
const Register = lazy(() => import('./views/Pages/Register/Register'))
const ResetPassword = lazy(() => import('./views/Pages/ResetPassword/ResetPassword'))
const ForgotPassword = lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'))
const Page404 = lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = lazy(() => import('./views/Pages/Page500/Page500'));
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));

const App = (props) => {
    //Once Login Ready Then In Login Component  UnauthenticatedRoute
    //Once Login Required Of Any Page Then In AuthenticatedRoute

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <HashRouter>
                        <Suspense fallback={loading()}>
                            <ToastContainer theme="colored" />
                            <Routes>
                                <Route path="/" element={<PublicPage/>}>
                                    <Route index path={'/'} name={"Login"} element={<Login/>}/>
                                    <Route path={'/login'} name={"Login"} element={<Login/>}/>
                                    <Route path={"/register"} name={"Register"} element={<Register/>}/>
                                    <Route path={"/forgot-password"} name={"Forgot Password"}
                                           element={<ForgotPassword/>}/>
                                    <Route path={"/reset-password"} name={"Reset Password"} element={<ResetPassword/>}/>
                                    <Route path="/500" name="Page 500" element={<Page500/>}/>
                                </Route>
                                <Route path="/" element={<RequireAuth><DefaultLayout/></RequireAuth>}>
                                    <Route index path={'/dashboard'} element={<AddProps {...props} comp={Dashboard}/>}/>
                                    {routes.map((route, idx) => {
                                        return route.component && (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                element={<AddProps {...props} comp={route.component}/>}
                                            />
                                        );
                                    })}
                                </Route>
                                <Route path="/*" element={<Page404/>}/>
                            </Routes>
                        </Suspense>
                    </HashRouter>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
}

const RequireAuth = ({children}) => {
    const getAccessToken = () => localStorage.getItem('access_token');
    const isAuthenticated = () => !!getAccessToken()
    const location = useLocation();

    if (!isAuthenticated()) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
}

const AddProps = (props) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <props.comp {...props} {...{location, navigate, params}} />
}
export default App;
