import React from 'react';
import moment from "moment";
export const dispatchAction = (dispatch, type, payload = "undefined", debug = false) => {
    if (debug) {
        console.log('dispatchAction', type, payload);
        debugger;
    }
    const obj = (payload !== "undefined") ? { type, payload } : { type }
    return dispatch(obj);
}

export const getError = (error, defaultErr = "defaultErrorMessage", debug = false) => {
    if (error) {
        const { message } = error;
        const msg = message || defaultErr;
        console.log('getError', msg)
        if (debug) {
            debugger;
        }
        return msg;
    }
};

export const formatDate = (date = new Date(), format = 'YYYY-MM-DD', isConvertUTC = false) => {
    return isConvertUTC ? moment(date).utc().format(format) : moment(date).format(format);
}

export const isEmpty = (value) => {
    if (value === undefined || value === null || value === '') {
        return true;
    } else if (typeof value === 'string') {
        return value.trim() == "";
    } else {
        return false;
    }
}