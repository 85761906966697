import {getError} from "../../utils/common";
import http from '../../utils/Interceptor';
import {toast} from 'react-toastify';

export const SET_DATA = '[Reports] Set Redux Data';

export const getEmailReportList = (data) => async () => {
    try {
        return await http.post('getEmailReportList',data);
    } catch (e) {
        getError(e);
    }
}

export const RenewalFollowupReport = (data) => async () => {
    try {
        return await http.post('Renewal_Follow_up_Report',data);
    } catch (e) {
        getError(e);
    }
}

export const RenewalForecastingReport = (data) => async () => {
    try {
        return await http.post('Renewal_Forecasting_Report',data);
    } catch (e) {
        getError(e);
    }
}

export const RenewalNotSentReport = (data) => async () => {
    try {
        return await http.post('Renewal_Not_Sent_Report',data);
    } catch (e) {
        getError(e);
    }
}

export const getSMSReportList = (data) => async () => {
    try {
        return await http.post('getSMSReportList',data);
    } catch (e) {
        toast.error("Error",getError(e));
        
    }
}
export const dropdownbycodeforreason = (StingMapKey) => async () => {
    try {
        return await http.post('dropdownbycodeforreason', {StingMapKey});
    } catch (e) {
        getError(e);
    }
}