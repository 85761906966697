import {getError} from "../../utils/common";
import http from '../../utils/Interceptor';

export const SET_DATA = '[Reports] Set Redux Data';

export const ContactMissingReport = (data) => async () => {
    try {
        return await http.post('Contact_Missing_Report',data);
    } catch (e) {
        getError(e);
    }
}

export const sendSMSEMAIL = (data) => async () => {
    try {
        return await http.post('sendSMSEMAIL',data);
    } catch (e) {
        getError(e);
    }
}

export const getDinamicField = (data) => async () => {
    try {
        return await http.post('getDinamicField',data);
    } catch (e) {
        getError(e);
    }
}

export const getSmsEmailTemplate = (messagetype,loginId) => async () => {
    try {
        return await http.post('getSmsEmailTemplate',{messagetype,loginId});
    } catch (e) {
        getError(e);
    }
}

export const getSmsEmailCount = (data) => async () => {
    try {
        return await http.post('getSmsEmailCount',data);
    } catch (e) {
        getError(e);
    }
}

export const MigrationReportAPI = (data) => async () => {
    try {
        return await http.post('MigrationReportAPI',data);
    } catch (e) {
        getError(e);
    }
}

export const monitoringReport = () => async () => {
    try {
        return await http.post('MonitoringReport');
    } catch (e) {
        getError(e);
    }
}

export const getAllBinderList = (BinderType) => async () => {
    try {
        return await http.post('getAllBinderList',{BinderType});
    } catch (e) {
        getError(e);
    }
}

export const getMooringNames = () => async () => {
    try {
        return await http.get('getMooringNames');
    } catch (e) {
        getError(e);
    }
}

export const searchMooringLocations = (data) => async () => {
    try {
        return await http.post('searchMooringLocations',data);
    } catch (e) {
        getError(e);
    }
}

export const getSanctionsSearchReport = (data) => async () => {
    try {
        return await http.post('getSanctionsSearchReport',data);
    } catch (e) {
        getError(e);
    }
}