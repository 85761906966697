import {SET_DATA} from "./action";

const defaultValue = {
    authUser: JSON.parse(localStorage.getItem('loggedInUserData')),
};

const reducer = (state = defaultValue, action = []) => {
    const { type, payload } = action;
    switch (type) {
        case SET_DATA:
            return {
                ...state,
                [payload.prop]: payload.data
            };
        default:
            return state;
    }
};

export default reducer;