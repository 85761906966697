import {SET_DATA} from "./action";

const defaultValue = {
    reasonList: [], //remove
    countryList: [], //remove
    refundReasonList: [],//remove
    paymentModearr: [],//remove
    returnPaymentModearr: [],//remove
};

const reducer = (state = defaultValue, action = []) => {
    const {type, payload} = action;
    switch (type) {
        case SET_DATA:
            return {
                ...state,
                [payload.prop]: payload.data
            };
        default:
            return state;
    }
};

export default reducer;