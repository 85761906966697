import {getError} from "../../utils/common";
import http from '../../utils/Interceptor';
import APIConstant from '../../utils/constants';

export const SET_DATA = '[Reports] Set Redux Data';

export const agedDebtorsList = (data) => async () => {
    try {
        return await http.post('AgedDebtorsList', data);
    } catch (e) {
        getError(e);
    }
}

export const agentBatch = (data) => async () => {
    try {
        return await http.post('AgentBatch', data);
    } catch (e) {
        getError(e);
    }
}

export const agentCommissionReport = (data) => async () => {
    try {
        return await http.post('AgentCommissionReport', data);
    } catch (e) {
        getError(e);
    }
}

export const GetBulkRenewalList = (data) => async () => {
    try {
        return await http.post('GetBulkRenewalList', data);
    } catch (e) {
        getError(e);
    }
}

export const newGenerateRenewal = (newdata) => async () => {
    try {
        return await http.post('newGenerateRenewal', newdata);
    } catch (e) {
        getError(e);
    }
}

export const getGenerateDocumentRenewal = (newdata) => async () => {
    try {
        const res = await http.post('getGenerateDocumentRenewal', newdata);
        return (res.success) ? res.data : '';
    } catch (e) {
        getError(e);
    }
}

export const generatebulkDocument = (data) => async () => {
    try {
        return await http.post('generatebulkDocument', data);
    } catch (e) {
        getError(e);
    }
}
export const generatebulkDocument2 = (data) => async () => {
    try {
        return await http.post('generatebulkDocument2', data);
    } catch (e) {
        getError(e);
    }
}

export const removeReconciliationDetail = (data) => async () => {
    try {
        return await http.post(APIConstant.path.removeReconciliationDetail, data);
    } catch (e) {
        getError(e);
    }
}

export const addReconciliationData = (data) => async () => {
    try {
        return await http.post(APIConstant.path.addReconciliationData, data);
    } catch (e) {
        getError(e);
    }
}

export const getReconciliationDetail = (data) => async () => {
    try {
        return await http.post(APIConstant.path.getReconciliationDetail, data);
    } catch (e) {
        getError(e);
    }
}

export const getCashbookDetails = (data) => async () => {
    try {
        return await http.post('getCashbookDetails', data);
    } catch (e) {
        getError(e);
    }
}

export const GetStripeBatchPaymentDetail = (data) => async () => {
    try {
        return await http.post('GetStripeBatchPaymentDetail', data);
    } catch (e) {
        getError(e);
    }
}

export const getBinderListByYear = (BinderYear) => async () => {
    try {
        return await http.post('getBinderListByYear', {BinderYear});
    } catch (e) {
        getError(e);
    }
}

export const getClaimBordereauxDetails = (data) => async () => {
    try {
        const res = await http.post('getClaimBordereauxDetails', data);
        return (res?.success) ? res.data : '';
    } catch (e) {
        return getError(e);
    }
}

export const getInsuranceCreditorsReport = (data) => async () => {
    try {
        return await http.post('getInsuranceCreditorsReport', data);
    } catch (e) {
        getError(e);
    }
}

export const getPremiumBordereauxDetails = (data) => async () => {
    try {
        return await http.post('getPremiumBordereauxDetails', data);
    } catch (e) {
        return getError(e);
    }
}

export const getPremiumBorderauxList = (data) => async () => {
    try {
        return await http.post('getPremiumBorderauxList', data);
    } catch (e) {
        getError(e);
    }
}

export const viewBordereaux = (data) => async () => {
    try {
        return await http.post('viewBordereaux', data);
    } catch (e) {
        getError(e);
    }
}

export const processBordereaux = (data) => async () => {
    try {
        return await http.post('processBordereaux', data);
    } catch (e) {
        getError(e);
    }
}

export const includeExcludeBordereaux = (data) => async () => {
    try {
        return await http.post('processBordereaux', data);
    } catch (e) {
        getError(e);
    }
}

export const getClaimBorderauxList = (data) => async () => {
    try {
        return await http.post('getClaimBorderauxList', data);
    } catch (e) {
        getError(e);
    }
}

export const viewClaimBordereaux = (data) => async () => {
    try {
        return await http.post('viewClaimBordereaux', data);
    } catch (e) {
        getError(e);
    }
}

export const servicecenterstatement = (data) => async () => {
    try {
        return await http.post('servicecenterstatement', data);
    } catch (e) {
        getError(e);
    }
}

export const deleteStripeReport = (data) => async () => {
    try {
        return await http.post('deleteStripeReport', data);
    } catch (e) {
        getError(e);
    }
}

export const viewStripeReport = (StripePaymentReportHeaderId) => async () => {
    try {
        return await http.post('viewStripeReport', {StripePaymentReportHeaderId});
    } catch (e) {
        getError(e);
    }
}

export const SEPADirectDebit_Report = (data) => async () => {
    try {
        return await http.post('SEPADirectDebitReport', data);
    } catch (e) {
        getError(e);
    }
}

export const StripeRefundReport = (data) => async () => {
    try {
        return await http.post('StripeRefundReport', data);
    } catch (e) {
        getError(e);
    }
}

export const generateStripeFinancialReport = (data) => async () => {
    try {
        return await http.post('generateStripeFinancialReport', data);
    } catch (e) {
        getError(e);
    }
}

export const getSavedStripeReports = (data) => async () => {
    try {
        return await http.post('getSavedStripeReports', data);
    } catch (e) {
        getError(e);
    }
}

export const StatusChangeForSEPADirectDebit = (policID) => async () => {
    try {
        return await http.post('StatusChangeForSEPADirectDebit', {policID});
    } catch (e) {
        getError(e);
    }
}

export const SepaForecastingReport = (data) => async () => {
    try {
        return await http.post('SepaForecastingReport', {data});
    } catch (e) {
        getError(e);
    }
}

export const StripeReconciliation = (StripePaymentReportHeaderId) => async () => {
    try {
        return await http.post('StripeReconciliation',{StripePaymentReportHeaderId});
    } catch (e) {
        getError(e);
    }
}

export const regenerateOnDemandSubAgentStatement = (data) => async () => {
    try {
        return await http.post('regenerateOnDemandSubAgentStatement', data);
    } catch (e) {
        getError(e);
    }
}

export const regenerateOnDemandAllSubAgentStatements = (data) => async () => {
    try {
        return await http.post('regenerateOnDemandAllSubAgentStatements', data);
    } catch (e) {
        getError(e);
    }
}

export const SubAgentAutoUpadateStage = (data) => async () => {
    try {
        return await http.post('SubAgentAutoUpadateStage', data);
    } catch (e) {
        getError(e);
    }
}

export const autosubagentstatement = (data) => async () => {
    try {
        return await http.post('autosubagentstatement', data);
    } catch (e) {
        getError(e);
    }
}

export const viewAutoSubAgentStatement = (id) => async () => {
    try {
        return await http.post('viewAutoSubAgentStatement', {id});
    } catch (e) {
        getError(e);
    }
}

export const SubAgentPayment_Report = (data) => async () => {
    try {
        return await http.post('SubAgentPaymentReport', data);
    } catch (e) {
        getError(e);
    }
}

export const subagentstatement = (data) => async () => {
    try {
        return await http.post('subagentstatement', data);
    } catch (e) {
        getError(e);
    }
}

export const newRecordPayment = (data) => async () => {
    try {
        return await http.post('newRecordPayment', data);
    } catch (e) {
        return getError(e);
    }
}