import React from 'react';
import moment from 'moment';

//=============PATH=================//
export default class APIConstant {
    static path = {
        login: 'auth/login',
        register: 'auth/signup',
        resetpassword: 'auth/reset_password',
        forgotpassword: 'auth/forgot_password',
        dropdownbycode: 'dropdownByCode',
        dropdownbycodeforreason: 'dropdownByCodeForReason',
        getcommondropdown: 'getCommonDropdown',
        addContactDetails: 'addContactDetails',
        getContact: 'getContact',
        getInsuranceCompanyList: 'getInsuranceCompanyList',
        getVesselClass: 'getVesselClass',
        getVesselClassForBinder: 'getVesselClassForBinder',
        getVesselType: 'getVesselType',
        getDefaultAddonCoverage: 'getDefaultAddonCoverage',
        getAssumption: 'getAssumption',
        getUserBankAccountDetails: 'getUserBankAccountDetails',
        getNotesbyEntityTypeAndEntityId: 'getNotesbyEntityTypeAndEntityId',
        getActiveReferralNotes: 'getActiveReferralNotes',
        getUserPolicyList: 'getUserPolicyList',
        getPolicyClientName: 'getPolicyClientName',
        getAllEmailList: 'getAllEmailList',
        getAllcontactList: 'getAllcontactList',
        getAllpolicyNumber: 'getAllpolicyNumber',
        getpolicyNumberById: 'getpolicyNumberById',
        getAllBinder: 'getAllBinder',
        getSubBinderByBinderId: 'getSubBinderByBinderId',
        isRenewalComutationActive: 'isRenewalComutationActive',
        getNextPolicyNumer: 'getNextPolicyNumer',
        checkPolicyNumberExists: 'checkPolicyNumberExists',
        cancelPolicy: 'cancelPolicy',
        isGenerateRenewal: 'isGenerateRenewal',
        generateRenewal: 'generateRenewal',
        confirmRenewal: 'confirmRenewal',
        migratedData: 'migratedData',
        getNextBinderDetails: 'getNextBinderDetails',
        getVesselClassIdFromName: 'getVesselClassIdFromName',
        getVesselTypeIdFromNameandClassId: 'getVesselTypeIdFromNameandClassId',
        getMooringNameAndTypeSeperated: 'getMooringNameAndTypeSeperated',
        setPremiumPolicyType: "setPremiumPolicyType",
        getTPOType: "getTPOType",
        getVesselClassByCountry: "getVesselClassByCountry",
        getVesselTypeByCountry: "getVesselTypeByCountry",
        getLaidUpAshoreModalData: "getLaidUpAshoreModalData",
        saveLaidUpMidTerm: "saveLaidUpMidTerm",
        getCancelPolicyModelData: "getCancelPolicyModelData",
        autoBinderByCountry: "autoBinderByCountry",
        clonePolicy: "clonePolicy",
        assignSpanishDebitFlag: "assignSpanishDebitFlag",
        removeSpanishDebitFlag: "removeSpanishDebitFlag",
        //BinderRatingBands
        getBinderRatingBinder: "getBinderRatingBands",
        addUpdateBinderRatingBands: "addUpdateBinderRatingBands",
        bulkInsertBinderRatingBands: "bulkInsertBinderRatingBands",
        insertRatingBandsFromDetail: "insertRatingBandsFromDetail",
        //Binder
        getBinder: "getBinderDetails",
        deleteBinder: "deleteBinder",
        makeBinderActive: "makeBinderActive",
        addUpdateBinder: "addUpdateBinder",
        getInsuranceCompnay: "getInsuranceCompnay",
        getSecurityGroup: "getSecurityGroup",
        getBrokerList: "getOrganazationData",
        getLastBinderCode: "getlastbindercode",
        getselectedAuthorizeGroup: "getselectedAuthorizeGroup",
        getBrokerData: "getBrokerData",
        getBinderDataById: "getBinderDataById",

        //Authirised Class
        addUpdateAuthorizeClass: "addUpdateAuthorizeClass",
        getAuthorizeClass: "getAuthorizeClass",
        deleteAuthorizedClass: "deleteAuthorizeClass",

        //Bounder For Risk Locator
        getBounder: "getBounderRisk",
        getRiskLocator: "getRiskLocator",
        getAuthorisedBinderCountry: "getAuthorisedBinderCountry",
        getAuthorisedBinderCountryList: "getAuthorisedBinderCountryList",
        addBouder: "addUpdateBounderRisk",
        addRiskLocator: "addRiskLocator",
        addAuthorisedBinderCountry: "addAuthorisedBinderCountry",
        deleteBounder: "deleteBounderRisk",
        deleteAuthorisedBinderCountry: "deleteAuthorisedBinderCountry",
        //Transaction
        getTransaction: "getTransaction",
        getNextBinderList: "getNextBinderList",
        getSettlement: "getSettlement",
        getSubBinders: "getSubBinders",
        allEmail: "getInbox",
        getAttachment: "getAllAttachmentUnreadMail",
        addRemoveFlag: "addRemoveFlag",
        deleteMessage: "deleteMessage",
        getPolicyAutoComplete: "getPolicyAutoComplete",
        getEmailTemplate: "getEmailTemplate",
        getEmailBodyData: "getEmailBodyData",
        sendMailBulk: "sendMailBulk",
        sendReply: "sendReply",
        forwordMail: "forwordMail",
        sendReplyAll: "sendReplyAll",
        addUpdateTags: "addUpdateTags",

        //Claim
        addUpdateClaim: "addUpdateClaim",
        getClaimList: "getClaimList",
        getClaimListWherePaymentDue: "getClaimListWherePaymentDue",
        getPolicyDetailForClaim: "getPolicyDetailForClaim",
        addUpdateClaimSurveyer: "addUpdateClaimSurveyer",
        addUpdateClaimThirdParty: "addUpdateClaimThirdParty",
        addEditClaimReserve: "addEditClaimReserve",
        UpdateSingleFieldForClaim: "UpdateSingleFieldForClaim",
        UpdateClaimDetailReserve: "UpdateClaimDetailReserve",
        addClaimInvoice: "addClaimInvoice",
        getContactListFromContactType: "getContactListFromContactType",
        getClaimAccountList: "getClaimAccountList",
        claimProcessAccountEntry: "claimProcessAccountEntry",
        updateClaimPaymentTransaction: "updateClaimPaymentTransaction",
        GetChildClaimAccountRecords: "GetChildClaimAccountRecords",
        getClaimPaymentReportByUser: "getClaimPaymentReportByUser",

        //Sanctions Search
        getSanctionSearchResultDetails: "getSanctionSearchResultDetails",
        getUserDataForSanctionsSearch: "getUserDataForSanctionsSearch",
        startSanctionsSearch: "startSanctionsSearch",
        saveSanctionsSearchResult: "saveSanctionsSearchResult",
        updateSanctionsSearchResult: "updateSanctionsSearchResult",
        //Reconciliation in Cashbook
        addReconciliationData: "addReconciliationData",
        removeReconciliationDetail: "removeReconciliationDetail",
        getReconciliationDetail: "getReconciliationDetail",

        //Docusign Path
        DocusignPath: process.env.REACT_APP_DOCUSIGN_URI,
    }
};

//============Common Configurations========//

export class CommonConfig {
    static persistKey = process.env.REACT_APP_PERSIST;
    static appVersion = "V4.3.82";
    static appRegion = process.env.REACT_APP_ENV;
    static phpapi = process.env.REACT_APP_PHPAPI;

    static loggedInUserId = function () {
        if (localStorage.getItem('loggedInUserData')) {
            const loggedInUserData = JSON.parse(localStorage.getItem('loggedInUserData'));
            return loggedInUserData.PersonId;
        } else {
            //logout user
            return '999';
        }
    }

    static loggedInUserData = function () {
        if (localStorage.getItem('loggedInUserData')) {
            return JSON.parse(localStorage.getItem('loggedInUserData'));
        }
        return ""
    }


    static formatPhone = (CountryCode, AreaCode, PhoneNumber) => {
        let phone;
        if (CountryCode && CountryCode !== '' && CountryCode !== undefined && CountryCode !== null) {
            if (AreaCode && AreaCode !== '' && AreaCode !== undefined && AreaCode !== null) {
                phone = CountryCode + ' ' + AreaCode + ' ' + this.formatPhoneNumber(PhoneNumber);
            } else {
                phone = CountryCode + ' ' + this.formatPhoneNumber(PhoneNumber);
            }
        } else {
            if (AreaCode && AreaCode !== '' && AreaCode !== undefined && AreaCode !== null) {
                phone = AreaCode + ' ' + this.formatPhoneNumber(PhoneNumber);
            } else {
                phone = this.formatPhoneNumber(PhoneNumber);
            }
        }
        return phone;
    }

    static formatPhoneNumber(str) {
        //Filter only numbers from the input
        // let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        // let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        // if (match) {
        //   return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        // };

        if (str.length > 6) {
            return str.substring(0, 2) + ' ' + str.substring(2, 5) + ' ' + str.substring(5, 6) + ' ' + str.substring(6, 10);
        } else {
            return str;
        }

        // return null
    };

    static formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    };

    static formatCurrency = (value, currency = 'EUR', minimumFractionDigits = 0, maximumFractionDigits = 2) => {
        if (!this.isEmpty(value)) {
            return parseFloat(value).toLocaleString('en-IE', { style: 'currency', currency, minimumFractionDigits, maximumFractionDigits });
        }
        return value;
    }

    static chartColors = [
        "#00acc1",
        "#ff9800",
        "#f44336",
        "#666699",
        "#CC9933",
        "#006666",
        "#3399FF",
        "#993300",
        "#FFCC66",
        "#6699CC",
        "#663366",
        "#9999CC",
        "#669999",
        "#CC6600",
        "#9999FF",
        "#0066CC",
        "#99CCCC",
        "#FFCC00",
        "#009999",
        "#99CC33",
        "#FF9900",
        "#999966",
        "#66CCCC",
        "#339966",
        "#CCCC33",
        "#003f5c",
        "#665191",
        "#a05195",
        "#d45087",
        "#2f4b7c",
        "#f95d6a",
        "#ff7c43",
        "#ffa600",
        "#EF6F6C",
        "#465775",
        "#56E39F",
        "#59C9A5",
        "#5B6C5D",
        "#0A2342",
        "#2CA58D",
        "#84BC9C",
        "#CBA328",
        "#F46197",
        "#DBCFB0",
        "#545775"
    ];

    static funnelChartColors = [
        "#3B8DD4",
        "#DEBA50",
        "#E25C3C"
    ];

    static isEmpty = function (value) {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            if (typeof value === 'string') {
                return value.trim() == "";
            } else {
                return false;
            }
        }
    }

    static ThirdPartyStringMapOnCountry = function (country) {
        if (country == 'Spain') {
            return 'THIRDPARTYLIABILITYSPAIN';
        } else {
            return 'THIRDPARTYLIABILITY';
        }
    }

    static ShowHideLimitAdditionalFields = function (vessleClass) {
        if (vessleClass == 'RIB' || vessleClass == 'Sports Boat' || vessleClass == 'Neumática/Semirígida' || vessleClass == 'Jet Ski') {
            return {
                displayWater: 1,
                displayRacing: 0,
                displayCrew: 0
            };
        } else if (vessleClass == 'Racing Yacht' || vessleClass == 'Dinghy' || vessleClass == 'Cruiser / Racer') {
            return {
                displayWater: 0,
                displayRacing: 1,
                displayCrew: 0
            };
        } else if (vessleClass == 'Commercial Vessels') {
            return {
                displayWater: 0,
                displayRacing: 0,
                displayCrew: 1
            };
        } else {
            return {
                displayWater: 0,
                displayRacing: 0,
                displayCrew: 0
            };
        }
    }

    static GetFixedCrusingRange = function (boatUse, whereReg) {
        let CrusingRange = '';
        if (boatUse == 'Med Waters') {
            if (whereReg == 'Ireland') {
                CrusingRange = 'Inland & Coastal Waters of Ireland';
            } else if (whereReg == 'Spain') {
                CrusingRange = 'Coastal water of Spain Including Gibraltar (Excluding North Africa)';
            } else if (whereReg == 'United Kingdom') {
                CrusingRange = 'Mediterranean waters not east of 10 degrees east (Excluding North Africa)';
            }
        } else if (boatUse == 'Inland & Coastal UK') {
            if (whereReg == 'Ireland') {
                CrusingRange = 'Inland & Coastal Waters of Ireland';
            } else if (whereReg == 'Spain') {
                CrusingRange = 'Coastal water of Spain Including Gibraltar (Excluding North Africa)';
            } else if (whereReg == 'United Kingdom') {
                CrusingRange = 'Inland & Coastal Waters of UK';
            }
        }
        return CrusingRange;
    }

    static getFixedVesselTypeFromVesselClassName = function (vessleClassName) {
        let VesselType = '';
        if (vessleClassName == 'Motor Cruisers') {
            VesselType = 'Motorboat Coastal Waters';
        } else if (vessleClassName == 'Yacht') {
            VesselType = 'Cruising Yacht';
        } else if (vessleClassName == 'Rib') {
            VesselType = 'Rib';
        } else if (vessleClassName == 'Sports Boat') {
            VesselType = 'Sportsboat';
        } else if (vessleClassName == 'MarineTradeVessel') {
            VesselType = 'MarineTradeVessel';
        } else if (vessleClassName == 'MultiVessel') {
            VesselType = 'MultiVessel';
        } else if (vessleClassName == 'Engineering Vessel') {
            VesselType = 'Engineering Vessel';
        }
        return VesselType;
    }

    static ShowHideActionOnPolicyStatus = function (Status) {
        let condition = '';
        if (Status == 'Cancel' || Status == 'Lapsed') {
            condition = 0;
        } else {
            condition = 1;
        }
        return condition;
    }


    static dateFormat = {
        dateTime: 'DD/MM/YYYY HH:mm',
        dateTimeUpdated: 'DD/MM/YYYY HH:mm a',
        dateTimeWithDash: 'DD-MM-YYYY HH:mm',
        dateOnly: 'DD/MM/YYYY',
        YearMonthDay: 'YYYY-MM-DD',
        forDatePicker: 'DD-MM-YYYY',
        yearOnly: 'YYYY',
        dbDateTime: 'YYYY-MM-DD HH:mm:ss',
        timeOnly: 'HH:mm'
    }

    static dataTableConfig = {
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10, 20, 30, 50],
        columnToggleIcon: <i className="fa fa-bars"></i>,
        showingRecords: "Showing {first} to {last} of {totalRecords}",
        filterType: "row"
    }

    static RegExp = {

        percentage: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/,
        percentageWithNegative: /^-?[0-9]{0,2}(\.[0-9]{1,2})?$|^-?(100)(\.[0]{1,2})?$/,
        number: /^[0-9]+$/,
        // nameWithoutSpace: /^[a-zA-Z]+[a-zA-Z-']*$/,
        // nameWithSpace: /^[a-zA-Z]+[a-zA-Z-\s']*$/,
        nameWithoutSpace: /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+[a-zA-Z-\u00C0-\u024F\u1E00-\u1EFF']*$/,
        nameWithSpace: /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+[a-zA-Z-\u00C0-\u024F\u1E00-\u1EFF\s']*$/,
        email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, // /^(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,}$/,
        decimal: /^[0-9]+(\.[0-9][0-9])?$/,
        decimalWithOne: /^(?:\d*\.\d{1,2}|\d+)$/,
        decimalWithNegative: /^-?(?:\d*\.\d{1,2}|\d+)$/,  /*   /^-?[0-9]+(\.[0-9][0-9])?$/  */
        // alphaNumeric: /^[a-zA-Z0-9]+[a-zA-Z0-9-\s']*$/,
        alphaNumeric: /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF0-9]+[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF0-9-\s']*$/,
        year: /^[0-9]{4}$/,
        allowAllWithSpace: /^(\w.*)$/,
        latlng: /^-?(\d*\.)?\d+$/,
    }


    static thousandSeparatorFunction = function (param) {
        return param.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    static commaReplaceInLongtext = (value) => {
        if (value == null || value == '') {
            return '';
        } else {
            return value.replace(/,/g, '|**##**|');
        }
    }

    static isDateValid = function (value) {
        return moment(value).isValid();
    }
    static googleMapApiDetails = {
        apiKey: process.env.REACT_APP_GMAP_KEY,
        apiURL: process.env.REACT_APP_GMAP_URL,
        googleMapApiURL: process.env.REACT_APP_GMAP_APP_URL,
    }
}