import _ from "lodash";
import {CLEAR_DATA, SET_DATA, STRING_MAP} from "./action";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";

const defaultValue = {
    currencyList: [],
    languageList: [],
    countryDataList: [],
    PolicyTypeList: [],
    renewalMonthList: [],
    contactModeList: [],
    ResponseTypeArray: [],
    noteType: [],
    cancelreasonList: [],
    adjustmentAmountList: [],
    thirdPartyLiabilityList: [],
    mooringBoatRegyarr: [],
    CruisingRangearr: [],
    countryList: [],
    policyTypeList: [],
    MTClassTypeList: [],
    premiumPolicyTypeList: [],
    UseofVesselList: [],
    vesselBuildTypearr: [],
    tenderVesselMaterialarr: [],
    vesselHullMaterialarr: [],
    noOfEnginearr: [],
    auxiliaryEngineNoEnginearr: [],
    tarnsmissionMainEnginearr: [],
    auxiliaryEngineTransmissionarr: [],
    auxiliaryEnginearr: [],
    sumInsuredMethodarr: [],
    departmentList: [],
    awaitingDocumentList: [],
    premiumCreditList: [],
    racingEventTypeList: [],
    crewLiabilityList: [],
    govtLevyList: [],
    currentNCBNoofYearsList: [],
    previousBoatingExperienceList: [],
    previousNCBNoofYearsList: [],
    previousInsurerList: [],
    options: [],
    tagTypeArray: [],
    displaynamemaster: [],
    paymentModearr: [],
    txnPayeeTypeArr: [],
    txnVatCatArr: [],
    claimStatusList: [],
    claimTypeArr: [],
    yesNoArr: [],
    claimReportedByArr: [],
    weatherconditionArr: [],
    excessTypeArr: [],
    claimTableFilterlist: [], //double check till here
    claimTableTypelist: [],
    claimStatusArr: [],
    claimPaymentStatusList: [],
    PeerReviewArray: [],
    BusinessArray: [],
    langContactType: [],
    langTitleArray: [],
    langCountryArray: [],
    langCommissionPayables: [],
    langPhoneTypes: [],
    langContactModes: [],
    contactTypeArr: [],
    userloginArray: [],
    directoryList: [],
    Priorityarr: [],
    TaskTypearr: [],
    Statusarr: [],
    Regardingarr: [],
    policyCountryList: [],
    lapseReasonList: [],
    refundReasonList: [],
    accountPaymentModeArr: [],
    returnPaymentModearr: [],
    PreviousBoatingExperienceList: [],
    CurrentNCBYearsList: [],
    sumInsuredMethodList: [],
    CrewLiabilityList: [],
    RacingEventTypeList: [],
    noOfEngineList: [],
    tarnsmissionMainEngineList: [],
    hullMaterialList: [],
    ThirdPartyLiabilityList: [],
    cruisingRangeList: [],
    policyStatusList: [],
    customNoteTypes: [],
    responseTypeArray: [],
    documentProcessList: [],
    requiredProposalDocusignAttachmentsArr: [],
    documentListType: [],
    categoryList: [],
    templateTypeList: [],
    templateCategoryList: [],
    dynamicFieldList: [],
    EndorsementType: [],
    endorsementStatus: [],
    targetTypeList: [],
    transmissionMainEngineList: [],
    whereReg: [],
    renewalMONTHList: [],
    reduxCruisingRangeList: [],
    rateBasis1arr: [],
    systemEndorsementArr: [],
    NumberOperator1Arr: [],
    NumberOperator2Arr: [],
    DateOperator1Arr: [],
    DateOperator2Arr: [],
    systemEndorsementTextArr: [],
    diaplayDateArr: [],
    sysConfigValueTypeArr: [],
    sysConfigStatusArr: [],
    FStatusArr: [],
    generateDocProcessList: [],
    customThirdPartyLiabilityList: [],
    customCountryList: [],
    customNoOfEngineList: [],
    customRacingEventTypeList: [],
    customTransmissionMainEngineList: [],
    customCrewLiabilityList: [],
    customRenewalMonthList: [],
    customPreviousBoatingExperienceList: [],
    customCruisingRangeList: [],
    rateBasisList: [],
    leavyTypeList: [],
    mooringLocationTypeList: [],
    mooringApprovalList: [],
    riskLocationTypeList:[],
    dateTypeList: [],
    bordereauxCountryList: [],
    paymentModeList: [],
    claimTypeList: [],
    contactTypeList: [],
    mooringCountryArr: [],
    mooringTypeArr: [],
    BODList: [],
    bStatementTypeList: [],
    monthList: [],
    sanctionsEntityTypeList: [],
    sanctionsStatusList: [],
    PaymentStatusList: [],
    PaymentForList: [],
    RefundReasonList: [],
    RefundStatusList: [],
    PaymentMethodList: [],
    premiumPolicyType: [],
    personQuestionList: [],
    organizationQuestionList: [],
    dd: {}
};

const reducer = persistReducer(
    {storage, key: "evolution", whitelist: ["dd", 'currencyList', 'languageList', 'countryDataList']},
    (state = defaultValue, action = []) => {
        const {type, payload} = action;
        switch (type) {
            case SET_DATA:
                return {
                    ...state,
                    [payload.prop]: payload.data
                };
                break;
            case CLEAR_DATA:
                return defaultValue;
                break;
            case STRING_MAP:
                // Following is demo how dropdown object will look like
                /*newDd = {
                    TEST: {
                        EN : [{},{}],
                        IE: [{},{}],
                        GB: [{},{}]
                    },
                    TEST2: {
                        EN : [{},{}],
                        IE: [{},{}]
                    }
                }*/
                let newDd = {};
                _.forEach(_.sortBy(payload, [(o) => o.StringMapName]), (value, key) => {
                    const langName = value.LanguageId.toLowerCase();
                    const stringMapType = value.StringMapType;
                    //check this Map type is already exist or not
                    const index = _.has(newDd, stringMapType);
                    if (!index) {
                        //if map type is not available then first add map type with language key
                        newDd = {...newDd, [stringMapType]: {[langName]: [value]}}
                    } else {
                        //check if language already exist or not
                        const index = _.has(newDd[stringMapType], langName);
                        if (!index) {
                            //if language is not exist then add new language object into string map name object
                            newDd = {...newDd, [stringMapType]: {...newDd[stringMapType], [langName]: [value]}}
                        } else {
                            //if language is exist then just push a new value in array
                            newDd[stringMapType][langName].push(value)
                        }
                    }
                })
                return {
                    ...state,
                    dd: newDd
                };
                break;
            default:
                return state;
        }
    }
);

export default reducer;