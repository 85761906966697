import {getError} from "../../../utils/common";
import http from '../../../utils/Interceptor';
import APIConstant from "../../../utils/constants";

export const SET_DATA = '[Search Policy] Set Redux Data';

export const getAllStageList = () => async () => {
    try {
        return await http.post('getAllStageList', {});
    } catch (e) {
        getError(e);
    }
}
export const getAgentServiceCenterList = (ContactType) => async () => {
    try {
        return await http.post('getAgentServiceCenterList', {ContactType});
    } catch (e) {
        getError(e);
    }
}
export const searchPolicy = (data) => async () => {
    try {
        return await http.post('searchPolicy', data);
    } catch (e) {
        getError(e);
    }
}

export const updateAssignedRole = (data) => async () => {
    try {
        return await http.post('updateAssignedRole', data);
    } catch (e) {
        getError(e);
    }
}

export const changePolicyHandler = (PolicyId, PersonId) => async () => {
    try {
        return await http.post('changePolicyHandler', {PolicyId, PersonId});
    } catch (e) {
        getError(e);
    }
}
export const getPolicyStage = (data) => async () => {
    try {
        return await http.post('getPolicyStage', data);
    } catch (e) {
        getError(e);
    }
}
export const assignPolicy = (data) => async () => {
    try {
        return await http.post('assignPolicy', data);
    } catch (e) {
        getError(e);
    }
}

export const getPolicyClientName = () => async () => {
    try {
        return await http.post(APIConstant.path.getPolicyClientName, {});
    } catch (e) {
        getError(e);
    }
}
export const getAllEmailList = () => async () => {
    try {
        return await http.post(APIConstant.path.getAllEmailList, {});
    } catch (e) {
        getError(e);
    }
}
export const getAllcontactList = () => async () => {
    try {
        return await http.post(APIConstant.path.getAllcontactList, {});
    } catch (e) {
        getError(e);
    }
}
export const getAllpolicyNumber = () => async () => {
    try {
        return await http.post(APIConstant.path.getAllpolicyNumber, {});
    } catch (e) {
        getError(e);
    }
}
export const getVesselClass = () => async () => {
    try {
        return await http.get(APIConstant.path.getVesselClass, {});
    } catch (e) {
        getError(e);
    }
}
export const getSubBinderByBinderId = (BinderId) => async () => {
    try {
        return await http.post(APIConstant.path.getSubBinderByBinderId, {BinderId});
    } catch (e) {
        getError(e);
    }
}
export const getVesselType = (VesselClassId) => async () => {
    try {
        return await http.post(APIConstant.path.getVesselType, {VesselClassId});
    } catch (e) {
        getError(e);
    }
}
export const getAllBinder = (data) => async () => {
    try {
        return await http.post(APIConstant.path.getAllBinder, data);
    } catch (e) {
        getError(e);
    }
}
