import React, {lazy} from 'react';

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/ClaimsDetail/:Id/:tab/:subTab', name: 'ClaimsDetail', component: lazy(() => import('../views/Claims/ClaimsDetail'))},
  { path: '/ClaimList/:Id?', name: 'ClaimListBasedOnPolicy', component: lazy(() => import('../views/Claims/ClaimList'))},
  { path: '/ClaimList', name: 'ClaimList', component: lazy(() => import('../views/Claims/ClaimList'))},
  { path: '/NewClaim/:Id', name: 'NewClaim', component: lazy(() => import('../views/Claims/NewClaim/NewClaim'))},
  { path: '/dashboard', name: 'Dashboard', component: lazy(() => import('../views/Dashboard/Dashboard'))},
  { path: '/teamWorkFlow', name: 'Team Work Flow', component: lazy(() => import('../views/TeamWorkFlow/TeamWorkFlow'))},
  { path: '/changePassword', name: 'Change Password', exact: true, component: lazy(() => import('../views/Pages/ChangePassword/ChangePassword')) },
  { path: '/contactList', name: 'Contact Management', component: lazy(() => import('../views/ContactManagement/ContactManagement'))},
  { path: '/SearchContact', name: 'Search Contact', component: lazy(() => import('../views/ContactManagement/SearchContact'))},
  { path: '/addContactDetails', name: 'Add New Contact', component: lazy(() => import('../views/ContactManagement/AddContactManagement'))},
  { path: '/editContactDetails/:id/:entityType', name: 'Edit Contact', component: lazy(() => import('../views/ContactManagement/AddContactManagement'))},
  { path: '/viewContactDetails/:id/:entityType', name: 'View Contact', component: lazy(() => import('../views/ContactManagement/ViewContactDetails'))},
  { path: '/tasks', name: 'Tasks', component: lazy(() => import('../views/ContactManagement/tasks'))},
  { path: '/ContactNotes', name: 'Notes', component: lazy(() => import('../views/ContactManagement/Notes'))},
  { path: '/ContactPolicy', name: 'Policy', component: lazy(() => import('../views/ContactManagement/Policy'))},
  { path: '/AddContactPolicy', name: 'Add Policy', component: lazy(() => import('../views/ContactManagement/AddPolicy'))},
  { path: '/ContactDocuments', name: 'Documents', component: lazy(() => import('../views/ContactManagement/Documents'))},
  { path: '/PolicyDetailsMerged/:id/:tab/:subTab', name: 'Policy', component: lazy(() => import('../views/Policy/PolicyDetailsMerged'))},
  { path: '/PolicyList/*', name: 'Policy', component: lazy(() => import('../views/Policy/PolicyList/PolicyList'))},
  { path: '/SearchPolicy', name: 'Policy', component: lazy(() => import('../views/Policy/SearchPolicy/SearchPolicy'))},
  { path: '/NewPolicy', name: 'NewPolicy', component: lazy(() => import('../views/Policy/New Policy/NewPolicy'))},
  { path: '/CreatePolicy/:Id/:PolicyId?', name: 'CreatePolicy', component: lazy(() => import('../views/Policy/New Policy/CreatePolicy'))},
  { path: '/UpdatePolicy/:Id/:PolicyId', name: 'UpdatePolicy', component: lazy(() => import('../views/Policy/New Policy/CreatePolicy'))},
  { path: '/GenerateQuote/:Id', name: 'GenerateQuote', component: lazy(() => import('../views/Policy/New Policy/GenerateQuote'))},
  { path: '/Master/MooringGuide', name: 'MooringGuide', component: lazy(() => import('../views/Master/MooringGuide/mooringGuide'))},
  { path: '/AddMooringGuide/', name: 'AddMooringGuide', component: lazy(() => import('../views/Master/MooringGuide/AddMooringGuide'))},
  { path: '/EditMooringGuide/:Id', name: 'AddMooringGuide', component: lazy(() => import('../views/Master/MooringGuide/AddMooringGuide'))},
  { path: '/EditMooringZone/:Id', name: 'AddMooringZone', component: lazy(() => import('../views/Master/MooringZones/AddMooringZone'))},
  { path: '/AddMooringZone/', name: 'AddMooringZone', component: lazy(() => import('../views/Master/MooringZones/AddMooringZone'))},
  { path: '/Master/MooringZone', name: 'MooringZone', component: lazy(() => import('../views/Master/MooringZones/MooringZone'))},
  { path: '/Master/LeadManagement', name: 'Lead Management', component: lazy(() => import('../views/Master/LeadManagement/LeadManagement'))},
  { path: '/Master/RiskQuestion', name: 'RiskQuestionList', component: lazy(() => import('../views/Master/RiskQuestion/RiskQuestionList'))},
  { path: '/Master/RiskQuestionHistory/:Id', name: 'RiskQuestionHistory', component: lazy(() => import('../views/Master/RiskQuestion/RiskQuestionHistory'))},
  { path: '/Master/Endorsement', name: 'EndorsementList', component: lazy(() => import('../views/Master/Endorsement/EndorsementList'))},
  { path: '/Master/RiskQuestion', name: 'RiskQuestionList', component: lazy(() => import('../views/Master/RiskQuestion/RiskQuestionList'))},
  { path: '/Master/RiskQuestionHistory/:Id', name: 'RiskQuestionHistory', component: lazy(() => import('../views/Master/RiskQuestion/RiskQuestionHistory'))},
  // { path: '/Master/Endorsement', name: 'EndorsementList', component: lazy(() => import('./views/Master/Endorsement/EndorsementList'))},
  { path: '/Master/Levy', name: 'Levy', component: lazy(() => import('../views/Master/Levy/Levy'))},
  { path: '/Master/LevyHistory/:Id', name: 'LevyHistory', component: lazy(() => import('../views/Master/Levy/LevyHistory'))},
  { path: '/Master/NoClaimBonus', name: 'NoClaimBonus', component: lazy(() => import('../views/Master/NoClaimBonus/NoClaimBonus'))},
  { path: '/Master/NoClaimBonusHistory/:Id', name: 'NoClaimBonusHistory', component: lazy(() => import('../views/Master/NoClaimBonus/NoClaimBonusHistory'))},
  { path: '/Master/Binder', name: 'Binder', component: lazy(() => import('../views/Binder/BinderManage'))},
  { path: '/Master/AddUpdateBinder/:Id', name: 'Add Update Binder', component: lazy(() => import('../views/Binder/AddUdateBinder/AddUpdateBinder'))},
  { path: '/Master/AddUpdateBinder', name: 'Add Update Binder', component: lazy(() => import('../views/Binder/AddUdateBinder/AddUpdateBinder'))},
  { path: '/Master/VesselType/:Id', name: 'VesselType', component: lazy(() => import('../views/Master/VesselType/VesselType'))},
  { path: '/Master/VesselType', name: 'VesselType', component: lazy(() => import('../views/Master/VesselType/VesselType'))},
  { path: '/Master/VesselClass', name: 'VesselClass', component: lazy(() => import('../views/Master/VesselClass/VesselClass'))},
  { path: '/Master/VesselClassHistory/:Id', name: 'VesselClassHistory', component: lazy(() => import('../views/Master/VesselClass/VesselClassHistory'))},
  { path: '/Master/Assumption', name: 'Assumption', component: lazy(() => import('../views/Master/Assumption/Assumption'))},
  { path: '/Master/StringMap', name: 'StringMap', component: lazy(() => import('../views/Master/StringMap/StringMap'))},
  { path: '/Master/StringMapHistory/:Id', name: 'StringMapHistory', component: lazy(() => import('../views/Master/StringMap/StringMapHistory'))},
  { path: '/Master/SysConfig', name: 'SysConfig', component: lazy(() => import('../views/Master/SysConfig/SysConfig'))},
  { path: '/Master/SysConfigHistory/:Id', name: 'SysConfigHistory', component: lazy(() => import('../views/Master/SysConfig/SysConfigHistory'))},
  { path: '/bulkdocument', name: 'BulkDocument', component: lazy(() => import('../views/SidebarNav/BulkDocument'))},
  { path: '/AgedDebtorsList', name: 'Aged Debtors List', component: lazy(() => import('../views/Reports/AgedDebtorsList'))},
  { path: '/AgentCommissionReport', name: 'Agent Commission Report', component: lazy(() => import('../views/Reports/AgentCommissionReport'))},
  { path: '/bordereaux', name: 'Premium Bordeaux', component: lazy(() => import('../views/Reports/PremiumBordereaux'))},
  { path: '/claimBordereaux', name: 'Claim Bordeaux', component: lazy(() => import('../views/Reports/ClaimBordereaux'))},
  { path: '/subagentstatement', name: 'SubAgent Statement', component: lazy(() => import('../views/Reports/SubAgentStatement'))},
  { path: '/SubAgentPaymentReport', name: 'SubAgent Payment Report', component: lazy(() => import('../views/Reports/SubAgentPaymentReport'))},
  { path: '/SubAgentAutoStatementSummary', name: 'Sub Agent Auto Statement Summary', component: lazy(() => import('../views/Reports/subAgentAutoStatementSummary'))},
  { path: '/ServiceCenterStatement', name: 'ServiceCenter Statement', component: lazy(() => import('../views/Reports/ServiceCenterStatement'))},
  { path: '/InsuranceCreditorsReport', name: 'Insurance Creditors Report', component: lazy(() => import('../views/Reports/InsuranceCreditorsReport'))},
  { path: '/cashbook', name: 'Cashbook In/Out', component: lazy(() => import('../views/Reports/CashbookInOut'))},
  { path: '/StripeReport', name: 'StripeReport', component: lazy(() => import('../views/Reports/StripeReport'))},
  { path: '/SMSReport', name: 'SMS Report', component: lazy(() => import('../views/Reports/SMSReport'))},
  { path: '/EmailReport', name: 'Email Report', component: lazy(() => import('../views/Reports/EmailReport'))},
  { path: '/MooringLocationReport', name: 'Mooring Location Report', component: lazy(() => import('../views/Reports/MooringLocationReport'))},
  { path: '/MonitoringReport', name: 'Monitoring Report', component: lazy(() => import('../views/Reports/MonitoringReport'))},
  { path: '/BulkRenewals', name: 'Bulk Renewals', component: lazy(() => import('../views/Reports/BulkRenewals'))},
  { path: '/MigrationReport', name: 'Migration Report', component: lazy(() => import('../views/Reports/MigrationReport'))},
  { path: '/Master/DocumentMaster', name: 'DocumentMaster', component: lazy(() => import('../views/Master/Documents/Documents'))},
  { path: '/Master/LeaderBoardConfig', name: 'LeaderBoard Config', component: lazy(() => import('../views/Master/LeaderBoard/LeaderBoardConfig'))},
  { path: '/Master/LeaderBoard', name: 'LeaderBoard', component: lazy(() => import('../views/Master/LeaderBoard/LeaderBoard'))},
  { path: '/reports', name: 'Reports', component: lazy(() => import('../views/Reports/Report'))},
  { path: '/Renewal_Follow_up_Report', name: 'Renewal Follow up Report', component: lazy(() => import('../views/Reports/Renewal_Follow_up_Report'))},
  { path: '/Renewal_Forecasting_Report', name: 'Renewal Forecasting Report', component: lazy(() => import('../views/Reports/Renewal_Forecasting_Report'))},
  { path: '/Renewal_Not_Sent_Report', name: 'Renewal Not Sent Report', component: lazy(() => import('../views/Reports/Renewal_Not_Sent_Report'))},
  { path: '/Contact_Missing_Report', name: 'Contact with missing Phone and Email', component: lazy(() => import('../views/Reports/Contact_Missing_Report'))},
  { path: '/Lead_Quotation_and_newbusiness_report', name: 'Lead Quotation and newbusiness report', component: lazy(() => import('../views/Reports/Lead_Quotation_and_newbusiness_report'))},
  { path: '/Peer_Review_Report', name: 'Peer Review Report', component : lazy(() => import('../views/Reports/Peer_Review_Report'))},
  { path: '/SanctionsSearchReport', name: 'Sanctions Search Report', component: lazy(() => import('../views/Reports/SanctionsSearchReport'))},
];

export default routes;