//import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import policyDetailsMerged from '../views/Policy/PolicyDetailsMerged/reducer';
import policyAccount from '../views/Policy/Account/reducer';
import policySidebarNav from '../views/SidebarNav/reducer';
import claims from '../views/Claims/reducer';
import auth from '../views/Pages/Login/reducer';
import teamWorkFlow from '../views/TeamWorkFlow/reducer';
import addUpdateBinder from '../views/Binder/AddUdateBinder/reducer';
import contactManagement from '../views/ContactManagement/reducer';
import newPolicy from '../views/Policy/New Policy/reducer';
import searchPolicy from '../views/Policy/SearchPolicy/reducer';
import reports from '../views/Reports/reducer';
import dashboard from '../views/Dashboard/reducer';
// import Apps from '../views/Apps/Email/reducer';
import commonSelect from '../views/CommonSelect/reducer';

const reducer = (history) =>
    combineReducers({
        //router: connectRouter(history),
        policyDetailsMerged,
        policyAccount,
        policySidebarNav,
        claims,
        auth,
        teamWorkFlow,
        addUpdateBinder,
        contactManagement,
        newPolicy,
        searchPolicy,
        reports,
        dashboard,
        // Apps,
        commonSelect
    });

export default reducer;