import {SET_DATA} from "./action";

const defaultValue = {
    gridData: [],
    loading: false,
    gridInfo: {
        page: 0,
        take: 10,
        order: '-null',
        sortBy: [],
        filters: [],
        search: '',
        filterStr: '',
        totalPage: 0,
        totalRow: 0
    },
    stageList: [],
    referrList: [],
    mooringTypearr: [],
    mooringBoatMooredarr: [],
    claimTableTypelist: [],
    claimTableFilterlist: [],
    monthList: [],
    referredToName: '',
    leadData: {},
    policyWarningMessagesReminders: [],
};

const reducer = (state = defaultValue, action = []) => {
    const { type, payload } = action;
    switch (type) {
        
        case SET_DATA:
            return {
                ...state,
                [payload.prop]: payload.data
            };
        default:
            return state;
    }
};

export default reducer;