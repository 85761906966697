import {
    APPEND_DATA,
    CLEAR_DASHBOARD,
    securityGroupName,
    SET_CLAIM_LIST,
    SET_COUNTS,
    SET_DATA,
    SET_INFO,
    SET_NOTES_DATA,
    SET_POLICY_LIST,
    SET_USER_INFO
} from "./action";
import {CommonConfig} from "../../utils/constants";

const isSuperUser = CommonConfig.loggedInUserData().SuperUser;
const defaultValue = {
    PolicyTypeList: [],
    noteType: [],
    policyTypeArray: [],
    renewalMonthList: [],
    contactModeList: [],
    ResponseTypeArray: [],
    policyStageCount: [],
    staffMemberList: [],
    securityGroup: securityGroupName,
    superUser: isSuperUser === 1,
    teamType: securityGroupName === "Underwriting" && isSuperUser !== 1 ? "New Business"
        : securityGroupName === "Marine Trade" ? "Renewal" : securityGroupName,
    info: {
        Renewal: {policyListByStage: {}, claimList: {}, counts: {}},
        "Marine Trade": {policyListByStage: {}, claimList: {}, counts: {}},
        "New Business": {policyListByStage: {}, claimList: {}, counts: {}},
        Underwriting: {policyListByStage: {}, claimList: {}, counts: {}}
    },
    staffId: isSuperUser === 1 ? "" : CommonConfig.loggedInUserData().EntityId,
    policyStatistics: {},
    policyStatisticsBar: {},
    claimStatisticsBar: {},
    claimPayment: [],
    notifications: {notifications: [], reminders: []},
    notificationCount: 0,
    openNotePopup: false,
    noteList: {'Policy': {}, 'Claim': {}, 'Other': {}},
    showNotesModal: {entityId: "", entityType: "", show: false, showClaim: false},
    referredModal: {show: false},
    changeHandlerModal: {show: false},
    notesReason: {},
    usersByTeam: {},
};

const reducer = (state = defaultValue, action = []) => {
    const {type, payload} = action;
    switch (type) {
        case SET_DATA:
            return {
                ...state,
                [payload.prop]: payload.data
            };
        case SET_POLICY_LIST:
            return {
                ...state,
                info: {
                    ...state.info,
                    [state.securityGroup]: {
                        ...state.info[state.securityGroup],
                        policyListByStage: {
                            ...state.info[state.securityGroup].policyListByStage,
                            [payload.prop]: payload.data
                        }
                    }
                }
            };
        case SET_CLAIM_LIST:
            return {
                ...state,
                info: {
                    ...state.info,
                    [state.securityGroup]: {
                        ...state.info[state.securityGroup],
                        claimList: {
                            ...state.info[state.securityGroup].claimList,
                            [payload.prop]: payload.data
                        }
                    }
                }
            };
        case SET_COUNTS:
            return {
                ...state,
                info: {
                    ...state.info,
                    [state.securityGroup]: {
                        ...state.info[state.securityGroup],
                        counts: {
                            ...state.info[state.securityGroup].counts,
                            [payload.prop]: payload.data
                        }
                    }
                }
            };
        case SET_INFO:
            return {
                ...state,
                info: {
                    ...state.info,
                    [state.securityGroup]: {
                        ...state.info[state.securityGroup],
                        [payload.prop]: payload.data
                    }
                }
            };
        case APPEND_DATA:
            return {
                ...state,
                [payload.prop]: {...state[payload.prop], ...payload.data}
            };
        case SET_NOTES_DATA:
            return {
                ...state,
                noteList: {
                    ...state.noteList, [payload.prop]: {
                        ...state.noteList[payload.prop],
                        [payload.entityId]: payload.data
                    }
                }
            };
        case SET_USER_INFO:
            return {
                ...state,
                securityGroup: payload.data.securityGroup,
                superUser: payload.data.superUser,
                teamType: payload.data.teamType,
                staffId: payload.data.staffId,
            };
        case CLEAR_DASHBOARD:
            return defaultValue;
        default:
            return state;
    }
};

export default reducer;