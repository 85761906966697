import {getError} from "../../../utils/common";
import http from '../../../utils/Interceptor';
import APIConstant from '../../../utils/constants';

export const SET_DATA = '[New Policy] Set Redux Data';

export const getBinderCountryFromPolicyId = (PolicyId) => async () => {
    try {
        return await http.post('getBinderCountryFromPolicyId', {PolicyId});
    } catch (e) {
        getError(e);
    }
}

export const getBinderByPolicyType = (stringMapType) => async () => {
    try {
        return await http.post('getBinderByPolicyType', {stringMapType});
    } catch (e) {
        getError(e);
    }
}
export const getVesselClassByCountry = (Country, TPO) => async () => {
    try {
        return await http.post(APIConstant.path.getVesselClassByCountry, {Country, TPO});
    } catch (e) {
        getError(e);
    }
}
export const getMooringGuideDetails = (data) => async () => {
    try {
        return await http.post('getMooringGuideDetails', data);
    } catch (e) {
        getError(e);
    }
}
export const getDefaultAddonCoverage = (VesselTypeId) => async () => {
    try {
        return await http.post(APIConstant.path.getDefaultAddonCoverage, {VesselTypeId});
    } catch (e) {
        getError(e);
    }
}
export const getAssumption = (VesselTypeId) => async () => {
    try {
        return await http.post(APIConstant.path.getAssumption, {VesselTypeId});
    } catch (e) {
        getError(e);
    }
}

export const getPolicyAddedCoverage = (PolicyId) => async () => {
    try {
        return await http.post('getPolicyAddedCoverage', {PolicyId});
    } catch (e) {
        getError(e);
    }
}

export const getPremiumAndCoverage = (data) => async () => {
    try {
        return await http.post('getPremiumAndCoverage', data);
    } catch (e) {
        getError(e);
    }
}
export const getInsuranceCompany = (data) => async () => {
    try {
        return await http.post('getInsuranceCompany', data);
    } catch (e) {
        getError(e);
    }
}

export const getNextPolicyNumer = () => async () => {
    try {
        return await http.post(APIConstant.path.getNextPolicyNumer);
    } catch (e) {
        getError(e);
    }
}
export const checkPolicyNumberExists = (PolicyNumber) => async () => {
    try {
        return await http.post(APIConstant.path.checkPolicyNumberExists, {PolicyNumber});
    } catch (e) {
        getError(e);
    }
}

export const addPolicyCovarage = (data) => async () => {
    try {
        return await http.post('addPolicyCovarage', data);
    } catch (e) {
        getError(e);
    }
}
export const saveBankAndQuote = (data) => async () => {
    try {
        return await http.post('saveBankAndQuote', data);
    } catch (e) {
        getError(e);
    }
}
export const getNotesbyEntityTypeAndEntityId = (EntityId, EntityType) => async () => {
    try {
        return await http.post(APIConstant.path.getNotesbyEntityTypeAndEntityId, {EntityId, EntityType});
    } catch (e) {
        getError(e);
    }
}
export const getVesselClassIdFromName = (data) => async () => {
    try {
        return await http.post(APIConstant.path.getVesselClassIdFromName, data);
    } catch (e) {
        getError(e);
    }
}
export const savePolicy = (data) => async () => {
    try {
        return await http.post('savePolicy', data);
    } catch (e) {
        getError(e);
    }
}
export const getVesselTypeIdFromNameandClassId = (data) => async () => {
    try {
        return await http.post(APIConstant.path.getVesselTypeIdFromNameandClassId, data);
    } catch (e) {
        getError(e);
    }
}
export const getClaimCount = (Id) => async () => {
    try {
        return await http.post('getClaimCount', {Id});
    } catch (e) {
        getError(e);
    }
}

export const deleteAddedCoverage = (data) => async () => {
    try {
        return await http.post('deleteAddedCoverage', data);
    } catch (e) {
        getError(e);
    }
}
