import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import './assets/style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './utils/i18n';

const root = createRoot(document.getElementById("evolutionSystem"));
root.render(<StrictMode><App/></StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();